import { useQuery } from '@tanstack/react-query';
import api from '../api.ts';
import { SETTINGS } from '../../constants/api.ts';
import type { Settings } from '../../types/settings.type.ts';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys.ts';

export default function useSettingsQuery() {
  return useQuery({
    queryKey: [QUERY_CACHE_KEYS.settings],
    queryFn: async () => await api.get<Settings>(SETTINGS),
  });
}
