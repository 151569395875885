import { useMutation } from '@tanstack/react-query';
import API_PATH from '../../constants/api.ts';
import useSessionTimeoutStore from '../../context/session-timeout-context.ts';
import type { HTTPResponse } from '../../types/api.type.ts';
import api from '../api.ts';

function useMutateCustomerChoice(gid: string) {
  const updateLastUserInteractionTime = useSessionTimeoutStore(s => s.updateLastUserInteractionTime);

  return useMutation({
    mutationFn: async (quoteGid: string) =>
      await api
        .post<HTTPResponse<Record<never, never>>>(API_PATH.customerChoice
          .replace(':gid', gid)
          .replace(':quote_gid', quoteGid))
        .then(async (res) => {
          if (res.session.refreshed_at) {
            updateLastUserInteractionTime(res.session.refreshed_at * 1000);
          }
          return res.data;
        }),
  });
}

export default useMutateCustomerChoice;
