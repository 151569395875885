import type { FC } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import useSendMagicLink from '../../../api/authentification/use-send-magic-link.ts';
import EmailQuestion from '../../../components/Question/EmailQuestion/EmailQuestion.tsx';
import ROUTES from '../../../constants/routes.ts';
import Button from '../../../shared/components/Button/Button.tsx';
import { REQUIRED_VALIDATION } from '../../../utils/question-validations.util.ts';
import { SEGMENT } from '../../../constants/analytics.ts';
import useSegment from '../../../hooks/use-segment.ts';
import type { SignInFormProps } from './SignInForm.type.ts';

import { containerCSS } from './SignInForm.style.ts';

const SignInForm: FC<SignInFormProps> = ({ customCSS }) => {
  const methods = useForm<{ email: string }>();
  const { mutateAsync: sendMagicLink, isPending: isSendMagicLinkPending } = useSendMagicLink();
  const navigate = useNavigate();

  const { track } = useSegment();

  const onSubmit = async (data: { email: string }): Promise<void> => {
    track(SEGMENT.events.authenticateLinkSent, {
      page: SEGMENT.pages.signIn,
    });

    const res = await sendMagicLink({ email: data.email });
    navigate(`${ROUTES.checkEmail}?email=${res.user.email}`, { state: { email: data.email } });
  };

  return (
    <div css={[containerCSS, customCSS]}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <EmailQuestion questionKey="email" label="Email" validations={[REQUIRED_VALIDATION]} trackingForbidden />
          <Button type="submit" fullWidth size="large" isLoading={isSendMagicLinkPending}>
            Send Link
          </Button>
        </form>
      </FormProvider>
    </div>
  );
};

export default SignInForm;
