import { type FC, useState } from 'react';

import Drawer from '@mui/material/Drawer';
import { NavLink, useParams } from 'react-router-dom';

import useUserQuery from '../../api/user/use-user-query.ts';
import MaticLogoIcon from '../../assets/svg/matic-logo.svg?react';
import UserIcon from '../../assets/svg/user.svg?react';
import ROUTES from '../../constants/routes.ts';
import type { DefaultRouteParams } from '../../types/route-params.type.ts';
import HamburgerMenu from '../HamburgerMenu/HamburgerMenu.tsx';
import LiveHelpButton from '../LiveHelpButton/LiveHelpButton.tsx';
import useSettingsQuery from '../../api/settings/use-settings-query.ts';
import NavigationFullscreen from './NavigationFullscreen/NavigationFullscreen.tsx';
import {
  accountButtonCSS,
  accountButtonInnerCSS,
  accountIconCSS,
  containerCSS,
  leftColCSS,
  logoCSS,
  menuButtonCSS,
  pageLinkCSS,
  pageLinkInnerCSS,
  pageLinksWrapperCSS,
  rightColCSS,
} from './PortalHeader.style';
import type { PortalHeaderProps } from './PortalHeader.type';

const PortalHeader: FC<PortalHeaderProps> = ({ customCSS, pageKey }) => {
  const { gid } = useParams() as DefaultRouteParams;
  const { data: settingsData } = useSettingsQuery();
  const { data: userData } = useUserQuery(gid);
  const [isOpen, setIsOpen] = useState(false);
  const userName = userData?.user.first_name || 'Account';
  const phoneNumber = settingsData?.phone_number || '';
  const email = settingsData?.email || '';

  const handleDrawerToggle = () => {
    setIsOpen(s => !s);
  };

  return (
    <div css={[containerCSS, customCSS]}>
      <div css={leftColCSS}>
        <HamburgerMenu customCSS={menuButtonCSS} isOpen={isOpen} onClick={handleDrawerToggle} />
        <NavLink to={ROUTES.insights.replace(':gid', gid)}>
          <MaticLogoIcon css={logoCSS} />
        </NavLink>
        <nav css={pageLinksWrapperCSS}>
          <NavLink css={pageLinkCSS} to={ROUTES.insights.replace(':gid', gid)}>
            {({ isActive }) => <span css={pageLinkInnerCSS(isActive)}>Insights</span>}
          </NavLink>
          <NavLink css={pageLinkCSS} to={ROUTES.policies.replace(':gid', gid)}>
            {({ isActive }) => <span css={pageLinkInnerCSS(isActive)}>Policies</span>}
          </NavLink>
        </nav>
      </div>
      <div css={rightColCSS}>
        <LiveHelpButton pageKey={pageKey} />
        <NavLink css={accountButtonCSS} to={ROUTES.account.replace(':gid', gid)}>
          {({ isActive }) => (
            <span css={accountButtonInnerCSS(isActive)}>
              <UserIcon css={accountIconCSS} />
              {userName}
            </span>
          )}
        </NavLink>
      </div>

      <Drawer
        variant="temporary"
        open={isOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '100%' },
        }}
      >
        <NavigationFullscreen
          gid={gid}
          userName={userName}
          phoneNumber={phoneNumber}
          email={email}
          onClose={handleDrawerToggle}
        />
      </Drawer>
    </div>
  );
};

export default PortalHeader;
