import * as d3 from 'd3';
import type {
  DataItem,
  HistoryData,
  ReplacementCostHistoryItem,
  TransformedPremiumData,
  TransformedReplacementCostData,
} from '../types/history.type';
import THEME from '../styles/theme';

export const chartMainColors = d3.scaleOrdinal<string>()
  .domain(['0', '1'])
  .range([THEME.color.violet87, THEME.color.azure62]);

export const chartFutureDataColor = d3.scaleOrdinal<string>()
  .domain(['0', '1'])
  .range([THEME.color.violet95, THEME.color.azure95]);

export const chartFutureDataBorderColor = d3.scaleOrdinal<string>()
  .domain(['0', '1'])
  .range([THEME.color.violet67, THEME.color.azure62]);

export function setBarAttributes(
  selection: d3.Selection<SVGRectElement, any, any, any>,
  x: d3.ScaleBand<string>,
  y: d3.ScaleLinear<number, number, never>,
  height: number,
  currentYear: string,
  padding: number,
) {
  selection
    .attr('class', 'bar')
    .attr('x', d => x(`${d.key}`)!)
    .attr('y', height - 1)
    .attr('width', x.padding(padding).bandwidth())
    .attr('height', 0)
    .attr('fill', d => (d.year > currentYear
      ? chartFutureDataColor(`${d.key}`)
      : chartMainColors(`${d.key}`)) as string)
    .attr('stroke', d => (d.year > currentYear ? chartFutureDataBorderColor(`${d.key}`) : 'none') as string)
    .attr('stroke-dasharray', d => (d.year > currentYear ? '4,2' : 'none'))
    .attr('stroke-width', 2)
    .transition()
    .duration(1000)
    .attr('y', d => y(d.value) - 1)
    .attr('height', d => height - y(d.value))
    .attr('pointer-events', 'none');
}

export function formatSuffix(d: number, currentYear: number): string {
  return d === currentYear ? `(Now)` : d > currentYear ? `(Est.)` : ``;
}

export function transformPremiumHistoryData(history: HistoryData): TransformedPremiumData {
  return Object.entries(history).map(([year, premiums]) => ({
    year,
    values: [premiums.policy_premium, premiums.quote_premium].map(Number), // Convert to numbers
  }));
}

export function transformReplacementCostData(
  history: HistoryData<ReplacementCostHistoryItem>,
): TransformedReplacementCostData {
  return Object.entries(history).map(([year, premiums]) => ({
    year,
    values: [
      { min: +premiums.replacement_cost_min, max: +premiums.replacement_cost_max },
      +premiums.replacement_cost,
    ],
  }));
}

export function getLatestYearObject<T extends DataItem>(data: T[]): T {
  const latestYear = Math.max(...data.map(d => Number(d.year))).toString();
  return data.find(item => item.year === latestYear)!; // return the object with the latest year
}
