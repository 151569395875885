import type { FC } from 'react';
import InfoRequestTooltip from '../../../components/InfoRequestTooltip/InfoRequestTooltip';
import { markCSS } from '../../../styles/chart.style';
import SIRV_IMAGES from '../../../constants/sirv-images';
import { SEGMENT } from '../../../constants/analytics.ts';
import { addressCSS, chartCSS, notificationCSS, titleCSS } from './CarrierOptionsChart.style';
import type { CarrierOptionsChartProps } from './CarrierOptionsChart.type';

const CarrierOptionsChart: FC<CarrierOptionsChartProps> = ({ state }) => {
  return (
    <div>
      <div css={titleCSS}>
        {/* TODO fix colors after design review by Oles */}
        {state === 'unknown'
          ? (
              <>
                <div css={markCSS('#558FFF')} />
                Quotes over time
              </>
            )
          : 'Quotes over time'}

      </div>
      <p css={addressCSS}>🏠 3838 Walhaven Rd.</p>

      <div css={chartCSS}>
        <img src={SIRV_IMAGES.chartPreview} alt="Premium chart" />

        {state === 'unknown'
          ? <InfoRequestTooltip page={SEGMENT.pages.carrierOptions} />
          : (
              <div css={notificationCSS}>
                <h3>Almost There!</h3>
                <p>We are still fetching quotes for your home. We will update you when the process is complete.</p>
              </div>
            )}
      </div>
    </div>
  );
};

export default CarrierOptionsChart;
