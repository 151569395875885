import type { FC } from 'react';

import ErrorComponent from '../../components/Error/Error';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import Page from '../../components/Page/Page';
import { PAGE_KEYS } from '../../constants/analytics';
import useTitle from '../../hooks/useTitle';

import '../../assets/index.scss';

const PAGE_TITLE = 'Something Went Wrong';

const Error: FC = () => {
  useTitle(PAGE_TITLE);

  return (
    <Page>
      <Header page={PAGE_KEYS.ERROR} />
      <ErrorComponent />
      <Footer />
    </Page>
  );
};

export default Error;
