export const CHART_DATA_SET = {
  status: 'ready',
  history: {
    2022: {
      policy_premium: '1500',
      quote_premium: '1230',
    },
    2023: {
      policy_premium: '1570',
      quote_premium: '1300',
    },
    2024: {
      policy_premium: '1500',
      quote_premium: '1230',
    },
    2025: {
      policy_premium: '1600',
      quote_premium: '1300',
    },
  },
};
