import type { FC } from 'react';

import useSettingsQuery from '../../api/settings/use-settings-query.ts';
import BaseFooter from '../../kit_deprecated/BaseFooter/BaseFooter';

const Footer: FC = () => {
  const { data } = useSettingsQuery();
  return (
    <BaseFooter
      copy={(
        <>
          {data?.disclaimers?.map(disclaimer => (
            <div className="u-mb-3" key={disclaimer}>
              {disclaimer}
            </div>
          ))}
        </>
      )}
    />
  );
};

export default Footer;
