import type { FC } from 'react';
import { useEffect } from 'react';

import { Global } from '@emotion/react';
import { Outlet, useParams } from 'react-router-dom';

import SessionExpiredModal from '../SessionExpiredModal/SessionExpiredModal.tsx';

import { globalCSS } from '../../styles/global.style.ts';
import type { DefaultRouteParams } from '../../types/route-params.type.ts';
import useUserQuery from '../../api/user/use-user-query.ts';
import analytics from '../../utils/analytics.util.ts';

const SessionRoot: FC = () => {
  const { gid } = useParams<DefaultRouteParams>();
  const { data: userData } = useUserQuery(gid, false);
  const userGid = userData?.user.gid;

  useEffect(() => {
    if (userGid) {
      analytics.identify(userGid, {
        email: analytics.generateTrackEmail(userGid),
      });
    }
  }, [userGid]);

  return (
    <>
      <Global styles={globalCSS} />
      <SessionExpiredModal />
      <Outlet />
    </>
  );
};

export default SessionRoot;
