import { css } from '@emotion/react';

import { mq } from '../../../styles/media-queries';

export const containerCSS = css`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 12px;
  ${mq[0]} {
    padding: 0;
  }
`;

export const mainBodyCSS = css`
  display: flex;
  justify-content: space-between;
  widht: 100%;
`;

export const agentBlockCSS = css`
  display: flex;
  gap: 24px;
  padding: 12px;
  flex-direction: column;
  align-items: center;
  ${mq[0]} {
    flex-direction: row;
  }
`;

export const agentInfoCSS = css`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const agentContactCSS = css`
  display: flex;
  gap: 24px;
  flex-direction: column;
  ${mq[0]} {
    flex-direction: row;
  }
`;

export const agentContactItemCSS = css`
  font-weight: 700;
  display: flex;
  gap: 8px;
  align-items: center;
  white-space: nowrap;
`;

export const paragraphCSS = css`
  font-size: 40px;
  font-weight: 700;
  line-height: 52px;
  text-align: center;
  ${mq[0]} {
    text-align: left;
  }
`;

export const insuranceImageCSS = css`
  display: none;
  ${mq[1]} {
    display: block;
  }
`;
