import type { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useScoreQuery from '../../../api/score/use-score-query.ts';
import HeartInShieldIcon from '../../../assets/svg/heart-in-shield.svg?react';
import HouseScoreIcon from '../../../assets/svg/house-score.svg?react';
import type { DefaultRouteParams } from '../../../types/route-params.type.ts';
import { ScoreType } from '../../../types/score.type.ts';
import ScoreCard from '../ScoreCard/ScoreCard.tsx';
import { ScoreGaugeType } from '../ScoreCard/ScoreCard.type.ts';
import ROUTES from '../../../constants/routes.ts';
import { DpCta } from '../../../types/digital-profile.type.ts';
import useDpNavigate from '../../../hooks/use-dp-navigate.ts';
import useSegment from '../../../hooks/use-segment.ts';
import { SEGMENT } from '../../../constants/analytics.ts';
import type { ScoreCardsProps } from './ScoreCards.type.ts';
import {
  HOW_WE_CALCULATE_PROTECTION_SCORE,
  HOW_WE_CALCULATE_RISK_SCORE,
  SCORE_DETAILS,
  revertRiskScoreValue,
} from './ScoreCards.util.ts';
import { containerCSS, scoreCardCSS } from './ScoreCards.style.ts';

const ScoreCards: FC<ScoreCardsProps> = ({ customCSS }) => {
  const { gid } = useParams() as DefaultRouteParams;
  const { data: riscScoreData } = useScoreQuery(gid, ScoreType.Risk);
  const { data: protectionScoreData } = useScoreQuery(gid, ScoreType.Protection);
  const navigate = useNavigate();
  const navigateToDp = useDpNavigate();

  const { track } = useSegment();

  const navigateDigitalProfile = () => {
    track(SEGMENT.events.unlockPortalDataRequested, {
      page: SEGMENT.pages.insights,
      location: SEGMENT.locations.scoreCards,
    });
    navigateToDp(DpCta.Score);
  };

  const navigateFactors = (scrollToId: 'scroll-to-risk' | 'scroll-to-protection') => {
    track(SEGMENT.events.insightsNavigationClicked, {
      target_page: SEGMENT.pages.snapshotDetails,
    });
    navigate(`${ROUTES.snapshotDetails.replace(':gid', gid)}`, { state: { scrollToId } });
  };

  return (
    <div css={[containerCSS, customCSS]}>
      <ScoreCard
        customCSS={scoreCardCSS}
        title="Risk Score"
        description={SCORE_DETAILS.risk}
        icon={<HeartInShieldIcon />}
        scoreGaugeType={ScoreGaugeType.Negative}
        score={revertRiskScoreValue(riscScoreData?.score.value, riscScoreData?.score.max_value)}
        scoreMax={riscScoreData?.score.max_value}
        factors={riscScoreData?.score.factors}
        tooltip={HOW_WE_CALCULATE_RISK_SCORE}
        scoreButtonLabel="View all risk factors"
        isLoading={!riscScoreData || !protectionScoreData}
        onViewAllFactorsClick={() => navigateFactors('scroll-to-risk')}
        onClick={navigateDigitalProfile}
      />
      <ScoreCard
        customCSS={scoreCardCSS}
        title="Protection Score"
        description={SCORE_DETAILS.protection}
        icon={<HouseScoreIcon />}
        scoreGaugeType={ScoreGaugeType.Positive}
        score={protectionScoreData?.score.value}
        scoreMax={protectionScoreData?.score.max_value}
        factors={protectionScoreData?.score.factors}
        tooltip={HOW_WE_CALCULATE_PROTECTION_SCORE}
        scoreButtonLabel="View all protection factors"
        isLoading={!protectionScoreData || !riscScoreData}
        onViewAllFactorsClick={() => navigateFactors('scroll-to-protection')}
        onClick={navigateDigitalProfile}
      />
    </div>
  );
};

export default ScoreCards;
