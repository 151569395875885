import { useEffect, useRef } from 'react';
import analytics from '../utils/analytics.util.ts';

function initAllIntegrations(integrations: { segment?: { key: string; enabled: boolean } }): void {
  if (integrations.segment?.enabled && integrations.segment.key) {
    analytics.init(integrations.segment.key);
  }

  // TODO: Implement Sentry with error handling
  // if (integrations.sentry?.dsn && integrations.sentry.environment) {
  //   Sentry.init({
  //     dsn: integrations.sentry.dsn,
  //     environment: integrations.sentry.environment,
  //     release: import.meta.env.VITE_APP_SENTRY_RELEASE,
  //     autoSessionTracking: false,
  //     denyUrls: [
  //       'http://localhost:5173',
  //       'http://localhost:3000',
  //       /^chrome:\/\//i,
  //       /^chrome-extension:\/\//i,
  //       /^moz-extension:\/\//i,
  //     ],
  //     integrations: [httpClientIntegration(), debugIntegration(), extraErrorDataIntegration()],
  //     initialScope: {
  //       tags: { sender: 'browser' },
  //     },
  //     beforeSend: (event, hint) => {
  //       if (isErrorToSkip(hint.originalException)) {
  //         return null;
  //       }
  //       return event;
  //     },
  //   });
  // }
}

function useAnalytics(integrations?: { segment?: { key: string; enabled: boolean } }): void {
  const readyRef = useRef<boolean>();

  useEffect(() => {
    if (import.meta.env.PROD && integrations?.segment && !readyRef.current) {
      try {
        readyRef.current = true;
        initAllIntegrations(integrations);
      }
      catch (error) {
        console.error('Failed to load analytics', error);
      }
    }
  }, [integrations]);
}

export default useAnalytics;
