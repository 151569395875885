import type { SerializedStyles } from '@emotion/react';
import { css } from '@emotion/react';
import { mq } from '../../styles/media-queries.ts';
import THEME from '../../styles/theme.ts';

const OPACITY_NUMBER = 20;

export function containerCSS(color: string): SerializedStyles {
  return css`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 8px;
  background-color: ${color};
  border-radius: ${THEME.size.borderRadius}px;
  min-height: 200px;
  box-sizing: border-box;
  ${mq[0]} {
    padding: 16px 20px;
    flex-direction: row;
  }
`;
}

export const contentCSS = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding: 20px;
`;

export function messageContainerCSS(color: string): SerializedStyles {
  return css`
  background-color: ${color}${OPACITY_NUMBER};
  border-radius: ${THEME.size.borderRadiusInner}px;
  padding: 16px;
  box-sizing: border-box;
  ${mq[0]} {
    max-width: 320px;
  }
`;
}

export const titleCSS = css`
  display: flex;
  align-items: flex-end;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  svg {
    margin-right: 8px;
  }
`;

export const messageCSS = css`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-top: 4px;
`;
