import { css } from '@emotion/react';
import { mq } from '../../../../styles/media-queries';
import THEME from '../../../../styles/theme';

export const assistanceCSS = css`
  display: flex;
  border: 1px solid ${THEME.color.gray30};
  border-radius: 8px;
  box-shadow: ${THEME.boxShadow.container};
  padding: 16px 12px;
  flex-direction: column;
  align-items: center;

  ${mq[0]} {
    flex-direction: row;
    padding: 32px;
  }
`;

export const imageCSS = css`
  display: block;
  width: 96px;
  height: 96px;
  flex-shrink: 0;
  margin-right: 20px;
`;

export const assistanceContentCSS = css`
  font-size: 14px;
  line-height: 18px;

  ${mq[0]} {
    display: flex;
  }
`;

export const assistanceTextCSS = css`
  margin-bottom: 20px;

  ${mq[0]} {
    text-align: left;
    max-width: 268px;
    margin-right: 44px;
    margin-bottom: 0;
  }

  ${mq[1]} {
    margin-right: 76px;
  }
`;

export const assistanceHeadingCSS = css`
  font-weight: bold;
  margin-bottom: 4px;

  ${mq[0]} {
    font-size: 20px;
    line-height: 28px;
  }
`;

export const assistanceDescriptionCSS = css`
  color: ${THEME.color.gray60};
  font-size: 16px;
  line-height: 20px;
`;

export const assistanceContactsCSS = css`
  ${mq[0]} {
    flex-shrink: 0;
  }
`;

export const contactsHeadingCSS = css`
  font-weight: bold;
  color: ${THEME.color.black};
  margin-bottom: 8px;

  ${mq[0]} {
    font-size: 20px;
    line-height: 28px;
  }
`;

export const linkCSS = css`
  display: flex;
  align-items: center;
  border-bottom: unset;
  font-weight: bold;
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const contactIconCSS = css`
  width: 20px;
  color: ${THEME.color.gray60};
  margin-right: 8px;
`;

export const containerInnerCSS = css`
  max-width: 800px;
  margin: 0 auto;
`;
