import { css } from '@emotion/react';
import THEME from '../../../styles/theme';
import { mq } from '../../../styles/media-queries';

export const postContainerCSS = css`
  text-decoration: none;
`;

export const postCardCSS = css`
  display: flex;
  flex-direction: column;
  gap: 20px;

  img {
    height: 160px;
    object-fit: cover;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  };
  
  h3, p {
    transition: color 0.3s ease-in-out;
  }
  
  &:active,
  &:focus,
  &:hover {
    h3, p {
      color: ${THEME.color.azure50};
    }
  }

  ${mq[1]} {
    flex-direction: row;
    height: 160px;

    img { 
      max-width: 200px;
      border-bottom-left-radius: 4px;
      border-top-right-radius: 0;
    }
  }
`;

export const textContainerCSS = css`
  padding: 0 25px 21px 21px;
  color: ${THEME.color.black};
  font-size: 14px;
  line-height: 20px;

  h3 {
    margin: 0 0 8px;
  }

  ${mq[1]} {
    padding: 21px 25px 21px 0;
  }
`;
