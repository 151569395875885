import type { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import OfferCard from '../../../components/OfferCard/OfferCard.tsx';
import THEME from '../../../styles/theme.ts';
import ImageWide from '../../../assets/svg/coverage-insight-image-wide.svg?react';
import ImageNarrow from '../../../assets/svg/coverage-insight-image-narrow.svg?react';
import useResponsive from '../../../hooks/use-responsive.ts';
import Button from '../../../shared/components/Button/Button.tsx';
import { pageButtonCSS } from '../../../styles/layout.style.ts';
import useDpNavigate from '../../../hooks/use-dp-navigate.ts';
import { DpCta } from '../../../types/digital-profile.type.ts';
import ROUTES from '../../../constants/routes.ts';
import type { DefaultRouteParams } from '../../../types/route-params.type.ts';
import useUserQuery from '../../../api/user/use-user-query.ts';
import useSegment from '../../../hooks/use-segment.ts';
import { SEGMENT } from '../../../constants/analytics.ts';
import type { CoverageInsightCardProps } from './CoverageInsightCard.type.ts';
import { containerCSS, imageCSS } from './CoverageInsightCard.style.ts';

const CoverageInsightCard: FC<CoverageInsightCardProps> = ({ customCSS }) => {
  const { gid } = useParams() as DefaultRouteParams;
  const { data: userData } = useUserQuery(gid);
  const profileCompleted = !!userData?.user.profile_completed_at;

  const navigate = useNavigate();
  const { isDesktop } = useResponsive();
  const navigateToDp = useDpNavigate();
  const { track } = useSegment();

  const onActionClick = () => {
    if (profileCompleted) {
      track(SEGMENT.events.insightsNavigationClicked, {
        target_page: SEGMENT.pages.replacementCost,
      });
      navigate(ROUTES.replacementCost.replace(':gid', gid));
    }
    else {
      track(SEGMENT.events.unlockPortalDataRequested, {
        page: SEGMENT.pages.insights,
        location: SEGMENT.locations.coverageInsightCard,
      });
      navigateToDp(DpCta.CoverageLevel);
    }
  };

  return (
    <div css={customCSS}>
      <OfferCard
        customCSS={containerCSS}
        title="Did you know?"
        message={(
          <>
            There are many factors that go into your dwelling coverage, including material and labor cost, inflation,
            home location, construction methods, and home age. This number is not fixed and you should make sure your
            home still has the right coverage it needs.
          </>
        )}
        backgroundColor={THEME.color.azure95}
        messageColor={THEME.color.azure68}
      >
        {isDesktop ? <ImageWide css={imageCSS} /> : <ImageNarrow css={imageCSS} />}
      </OfferCard>
      <Button customCSS={pageButtonCSS} variant="secondary" onClick={onActionClick}>
        {profileCompleted ? 'Check your coverage level' : 'Unlock your coverage level' }
      </Button>
    </div>
  );
};

export default CoverageInsightCard;
