import type { FC } from 'react';
import { useParams } from 'react-router-dom';

import useScoreQuery from '../../api/score/use-score-query.ts';
import ProtectionIconLarge from '../../assets/svg/factors/protection-factor-large.svg?react';
import ProtectionIconSmall from '../../assets/svg/factors/protection-factor-small.svg?react';
import ReplacementIconLarge from '../../assets/svg/factors/replacement-factor-large.svg?react';
import ReplacementIconSmall from '../../assets/svg/factors/replacement-factor-small.svg?react';
import RiskIconLarge from '../../assets/svg/factors/risk-factor-large.svg?react';
import RiskIconSmall from '../../assets/svg/factors/risk-factor-small.svg?react';
import FactorItem from '../../components/FactorItem/FactorItem.tsx';
import PageContainer from '../../components/PageContainer/PageContainer.tsx';
import SectionHeading from '../../components/SectionHeading/SectionHeading.tsx';
import useResponsive from '../../hooks/use-responsive.ts';
import SkeletonLoader from '../../shared/components/SkeletonLoader/SkeletonLoader.tsx';
import type { DefaultRouteParams } from '../../types/route-params.type.ts';
import { ScoreType } from '../../types/score.type.ts';

import { pageHeadingCSS, pageSubheadingCSS } from '../../styles/layout.style.ts';
import useScrollToBlockOnInit from '../../hooks/use-scroll-to-block-on-init.ts';
import { SEGMENT } from '../../constants/analytics.ts';
import useTrackPage from '../../hooks/use-track-page.ts';
import { activeTabCSS, factorCSS, factorsContainerCSS, tabWithTagCSS, tabsCSS, tagCSS } from './index.style.ts';

const SnapshotDetailsLayout: FC = () => {
  const { gid } = useParams() as DefaultRouteParams;
  const { isMobile } = useResponsive();
  const { data: riskScoreData } = useScoreQuery(gid, ScoreType.Risk);
  const { data: protectionScoreData } = useScoreQuery(gid, ScoreType.Protection);
  const { data: replacementScoreData } = useScoreQuery(gid, ScoreType.Replacement);

  useTrackPage(SEGMENT.pages.snapshotDetails);
  useScrollToBlockOnInit();

  const renderLoadingFactors = () => {
    const count = isMobile ? [1, 2, 3] : [1, 2, 3, 4, 5, 6];
    return count.map(key => <SkeletonLoader key={key} customCSS={factorCSS} absoluteHeight={40} width={100} />);
  };

  return (
    <PageContainer pageKey={SEGMENT.pages.snapshotDetails}>
      <h1 css={pageHeadingCSS}>Protection Snapshot Score Details</h1>
      <p css={pageSubheadingCSS}>
        Here we will break down your scores to help better explain their impact to how insurance carriers view insuring
        your assets.
      </p>
      <div css={tabsCSS}>
        <div css={activeTabCSS}>Home Protection Scores</div>
        <div css={tabWithTagCSS}>
          <span>Auto Protection Scores</span>
          <span css={tagCSS}>Coming Soon</span>
        </div>
      </div>

      <div id="scroll-to-risk" />
      <SectionHeading
        title="Risk Factors"
        subtitle="These scores help carriers understand how likely they would need to
        replace your asset due to an accident or natural cause."
        icon={isMobile ? <RiskIconSmall /> : <RiskIconLarge />}
        size="large"
      />
      <div css={factorsContainerCSS}>
        {riskScoreData
          ? riskScoreData.score.factors.map(f => (
            <FactorItem
              customCSS={factorCSS}
              key={f.key}
              title={f.title}
              titleHint={f.tooltip}
              value={f.display_value}
              valueHint={f.explanation}
              riskLevel={f.risk}
              impactLevel={f.impact_level}
            />
          ))
          : renderLoadingFactors()}
      </div>

      <div id="scroll-to-protection" />
      <SectionHeading
        title="Protection Factors"
        subtitle="These scores help carriers understand how proactive you are being to mitigate
        issues with the assets you are protecting. These scores often translate to discounts for carriers."
        icon={isMobile ? <ProtectionIconSmall /> : <ProtectionIconLarge />}
        size="large"
      />
      <div css={factorsContainerCSS}>
        {protectionScoreData
          ? protectionScoreData.score.factors.map(f => (
            <FactorItem
              customCSS={factorCSS}
              key={f.key}
              title={f.title}
              titleHint={f.tooltip}
              value={f.display_value}
              valueHint={f.explanation}
              riskLevel={f.risk}
              impactLevel={f.impact_level}
            />
          ))
          : renderLoadingFactors()}
      </div>

      <div id="scroll-to-replacement" />
      <SectionHeading
        title="Replacement Factors"
        subtitle="These scores help carriers understand how much it would cost to replace your asset."
        icon={isMobile ? <ReplacementIconSmall /> : <ReplacementIconLarge />}
        size="large"
      />
      <div css={factorsContainerCSS}>
        {replacementScoreData
          ? replacementScoreData.score.factors.map(f => (
            <FactorItem
              customCSS={factorCSS}
              key={f.key}
              title={f.title}
              titleHint={f.tooltip}
              value={f.display_value}
              valueHint={f.explanation}
              riskLevel={f.risk}
              impactLevel={f.impact_level}
            />
          ))
          : renderLoadingFactors()}
      </div>
    </PageContainer>
  );
};

export default SnapshotDetailsLayout;
