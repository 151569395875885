import type { SerializedStyles } from '@emotion/react';
import { css, keyframes } from '@emotion/react';
import THEME from '../../../styles/theme.ts';
import { mq } from '../../../styles/media-queries.ts';
import { linkCSS } from '../../../shared/components/Anchor/Anchor.style.ts';
import { QuoteStatus } from './QuoteItem.type.ts';

export const quoteWrapperCSS = css`
  min-height: 43px;
  border-bottom: 1px solid ${THEME.color.gray30};
`;

export const quoteInnerCSS = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding-bottom: 15px;
`;

export const rightColCSS = css`
  width: 200px;
  flex-shrink: 0;
  ${mq[1]} {
    width: 220px;
  }
`;

export const unlockButtonCSS = css`
  font-size: 14px;
  line-height: 20px;
  padding: 4px 12px;
`;

export const logoCSS = css`
  max-width: 120px;
  height: 28px;
  
  ${mq[0]} {
    max-width: 150px;
  }
`;

function getColor(status: QuoteStatus): string {
  switch (status) {
    case QuoteStatus.Ready:
      return THEME.color.positiveGreen27;
    case QuoteStatus.Failed:
      return THEME.color.errorRed;
    case QuoteStatus.Pending:
    default:
      return THEME.color.gray60;
  }
}

export function statusDotCSS(status: QuoteStatus): SerializedStyles {
  return css`
    width: 12px;
    height: 12px;
    background-color: ${getColor(status)};
    margin-right: 4px;
    border-radius: 50%;
    position: relative;
`;
}

export const loadingCSS = css`
  display: flex;
  align-items: flex-end;
  position: relative;
  font-weight: 700;
  color: ${THEME.color.gray60};
  white-space: nowrap;
  ${rightColCSS};
`;

export const loadingMessageCSS = css`
  display: flex;
  align-items: center;
`;

const customBounce = keyframes`
  0%, 30% {
    transform: translate(0, 0);
  }
  40% {
    transform: translate(0, -2px);
  }
  60% {
    transform: translate(0, 2px);
  }
  70%, 100% {
    transform: translate(0, 0);
  }
`;

export const customLoadingDotsCSS = css`
  padding: 0;
  position: relative;
  bottom: 4px;

  > * {
    width: 4px;
    height: 4px;
    background-color: ${THEME.color.gray60};
    animation: ${customBounce} 1.5s ease infinite;
  };

  > *:nth-child(1) { 
    animation-delay: 0s;
  };
  > *:nth-child(2) {
    animation-delay: 0.15s;
  };
  > *:nth-child(3) {
    animation-delay: 0.3s;
  };
`;

export const failedCSS = css`
  display: flex;
  align-items: center;
  font-size: 14px;
  ${rightColCSS};
`;

export const manualCSS = css`
  display: flex;
  align-items: center;
  font-size: 14px;
  width: 56%;
  min-width: 200px;
  svg {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    margin-right: 12px;
  }
  ${mq[0]} {
    svg {
      margin-right: 20px;
    }
  }
`;

export const textButtonCSS = css`
  ${linkCSS('default', false)};
  border: none;
  background: transparent;
  padding: 0;
`;

export const readyCSS = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  font-weight: 700;
  color: ${THEME.color.positiveGreen27};
  ${rightColCSS};
`;

export const readyMessageCSS = css`
  display: flex;
  align-items: center;
`;

export const readyButtonCSS = css`
  font-size: 14px;
  line-height: 20px;
  padding: 2px 10px;
`;
