import type { SerializedStyles } from '@emotion/react';
import type { Quote } from '../../../types/quote.type.ts';
import type { Policy } from '../../../types/policy.type.ts';

export interface QuoteDetailsProps {
  customCSS?: SerializedStyles;
  page: string;
  quote: Quote;
  autoQuote?: Quote;
  recentPolicy?: Policy;
}

export enum QuoteBundleType {
  BundleApplied = 'bundleApplied',
  MultiPolicyAvailable = 'multiPolicyAvailable',
}
