import type { FC } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SirvImage from '../../../shared/components/SirvImage/SirvImage.tsx';
import TooltipQuestionButton from '../../../shared/components/TooltipQuestionButton/TooltipQuestionButton.tsx';
import Button from '../../../shared/components/Button/Button.tsx';
import formatUtil from '../../../utils/format.util.ts';
import type { DefaultRouteParams } from '../../../types/route-params.type.ts';
import { getQuoteDeductible, getQuoteDwelling } from '../../../utils/quote.util.ts';
import QuoteCoverages from '../QuoteCoverages/QuoteCoverages.tsx';
import ROUTES from '../../../constants/routes.ts';
import THEME from '../../../styles/theme.ts';
import Tooltip from '../../../shared/components/Tooltip/Tooltip.tsx';
import useMutateCustomerChoice from '../../../api/quote/use-mutate-customer-choice.ts';
import useMutateLeadCreation from '../../../api/lead/use-mutate-lead-creation.ts';
import { SEGMENT } from '../../../constants/analytics.ts';
import useSegment from '../../../hooks/use-segment.ts';
import {
  autoExplainerCSS,
  carrierLogoCSS,
  newPremiumCSS,
  premiumContainerCSS,
  renewedPremiumCSS,
  savingsContainerCSS,
  savingsValueCSS,
  tagCSS,
  valueColCSS,
  valueNumberCSS,
  valueTooltipCSS,
  valuesContainerCSS,
} from './QuoteDetails.style.ts';
import type { QuoteDetailsProps } from './QuoteDetails.type.ts';
import { QuoteBundleType } from './QuoteDetails.type.ts';
import {
  DEDUCTIBLE_TOOLTIP_CONTENT,
  DWELLING_TOOLTIP_CONTENT,
  POLICY_START_DATE_TOOLTIP_CONTENT,
  SAVINGS_TOOLTIP_CONTENT,
  getBundleType,
} from './QuoteDetails.util.ts';

const QuoteDetails: FC<QuoteDetailsProps> = ({ customCSS, page, quote, autoQuote, recentPolicy }) => {
  const { gid } = useParams() as DefaultRouteParams;
  const navigate = useNavigate();
  const {
    mutateAsync: submitCustomerChoice,
    isPending: isSubmittingCustomerChoicePending,
  } = useMutateCustomerChoice(gid);
  const { mutateAsync: createLead, isPending: isCreateLeadPending } = useMutateLeadCreation(gid);

  const { track } = useSegment();

  const savings = recentPolicy?.premium ? Number(Number(recentPolicy.premium) - quote.premium) : 0;

  const bundleType = getBundleType(quote, autoQuote);
  const mpdHomePremium = autoQuote && quote.mpd_discount ? quote.premium - quote.mpd_discount : null;

  const tooltipContent = `We found auto quotes for you starting 
   at ${formatUtil.money(autoQuote?.premium || 0, { prefix: '$' })} with ${autoQuote?.carrier.name}. 
   Our licensed advisors need to get some additional details to confirm your final bundle price.`;

  useEffect(() => {
    track(SEGMENT.events.suggestedPolicyViewed, {
      page,
      type: 'success',
      quotes: [quote, autoQuote].filter(Boolean),
      savings,
    });
  }, [track, page, savings, quote, autoQuote]);

  const handleActionButtonClick = async () => {
    track(SEGMENT.events.quoteSelected, {
      page,
      location: SEGMENT.locations.suggestedPolicy,
      quote_gid: quote.gid,
      carrier_key: quote.carrier.key,
      policy_type: quote.policy_type,
      premium_value: Number(quote.premium),
    });
    await createLead();
    await submitCustomerChoice(quote.gid);
    navigate(ROUTES.scheduleCall.replace(':gid', gid));
  };

  const renderQuoteTag = () => {
    if (bundleType === QuoteBundleType.BundleApplied) {
      return (
        <>
          <div css={tagCSS(THEME.color.positiveGreen27)}>Bundle Discount Applied</div>
          <Tooltip content={tooltipContent} customCSS={{ buttonCSS: autoExplainerCSS }}>
            Where is my auto quote?
          </Tooltip>
        </>
      );
    }

    if (bundleType === QuoteBundleType.MultiPolicyAvailable) {
      return (
        <>
          <div css={tagCSS(THEME.color.violet)}>Multi-Policy Available</div>
          <Tooltip content={tooltipContent} customCSS={{ buttonCSS: autoExplainerCSS }}>
            Where is my auto quote?
          </Tooltip>
        </>
      );
    }

    return null;
  };

  return (
    <div css={customCSS}>
      <SirvImage customCSS={carrierLogoCSS} url={quote.carrier.logo_url} width={300} />

      <div css={premiumContainerCSS}>
        <div css={newPremiumCSS}>
          New Premium: <span>{formatUtil.money(mpdHomePremium || quote.premium, { prefix: '$', suffix: '/yr' })}</span>
        </div>

        {!!recentPolicy?.premium && (
          <div css={renewedPremiumCSS}>
            Your renewed premium: {formatUtil.money(recentPolicy.premium, { prefix: '$', suffix: '/yr' })}
          </div>
        )}

        {renderQuoteTag()}
      </div>

      {savings > 0 && (
        <div css={savingsContainerCSS}>
          <div>
            Estimated Savings <TooltipQuestionButton content={SAVINGS_TOOLTIP_CONTENT} size={12} />
          </div>
          <div css={savingsValueCSS}>
            {formatUtil.money(savings, { prefix: '$' })}
          </div>
        </div>
      )}

      <div css={valuesContainerCSS}>
        <div css={valueColCSS}>
          <div>
            Deductible
            <TooltipQuestionButton customCSS={valueTooltipCSS} content={DEDUCTIBLE_TOOLTIP_CONTENT} size={12} />
          </div>
          <div css={valueNumberCSS}>
            {formatUtil.money(getQuoteDeductible(quote), { prefix: '$' })}
          </div>
        </div>
        <div css={valueColCSS}>
          <div>
            Dwelling Coverage
            <TooltipQuestionButton customCSS={valueTooltipCSS} content={DWELLING_TOOLTIP_CONTENT} size={12} />
          </div>
          <div css={valueNumberCSS}>
            {formatUtil.money(getQuoteDwelling(quote), { cutFromK: 2, prefix: '$' })}
          </div>
        </div>
        <div css={valueColCSS}>
          <div>
            Policy Start Date
            <TooltipQuestionButton customCSS={valueTooltipCSS} content={POLICY_START_DATE_TOOLTIP_CONTENT} size={12} />
          </div>
          <div css={valueNumberCSS}>{formatUtil.date(quote.effective_date)}</div>
        </div>
      </div>

      <Button
        fullWidth
        isLoading={isSubmittingCustomerChoicePending || isCreateLeadPending}
        onClick={handleActionButtonClick}
      >
        Continue
      </Button>
      {quote.coverages && <QuoteCoverages coverages={quote.coverages} onActionButtonClick={handleActionButtonClick} />}
    </div>
  );
};

export default QuoteDetails;
