// TODO: Add the actual values for the below constants
export const HOW_WE_CALCULATE_RISK_SCORE = 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. '
  + 'Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, '
  + 'when an unknown printer took a galley of type and scrambled it';

export const HOW_WE_CALCULATE_PROTECTION_SCORE = 'Lorem Ipsum is simply dummy text of the printing and '
  + 'typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, '
  + 'when an unknown printer took a galley of type and scrambled it';

export const SCORE_DETAILS = {
  risk: 'This score helps you understand how carriers view how risky you are to insure and what you can do about it.',
  protection: 'How protected are you? Fill out a short survey to understand how covered you are during a disaster.',
};

export function revertRiskScoreValue(value?: number | null, maxValue?: number | null): number | null {
  if (typeof value !== 'number' || typeof maxValue !== 'number') {
    return null;
  }

  return maxValue - value;
}
