import { css } from '@emotion/react';
import THEME from '../../../styles/theme.ts';
import { mq } from '../../../styles/media-queries.ts';

export const containerCSS = css`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 60px -${THEME.size.paddingBodyHorizontalMobile}px 0;
  border-radius: ${THEME.size.borderRadius}px;
  box-shadow: ${THEME.boxShadow.container};
  background-color: ${THEME.color.aqua95};
  padding: 20px;
  border-top: 4px solid ${THEME.color.aqua};
  ${mq[0]} {
    margin: 60px 0 0;
    flex-direction: row;
    justify-content: space-between;
    gap: 36px;
  }
`;

export const leftColCSS = css`
  ${mq[0]} {
    max-width: 310px;
  }
`;

export const rightColCSS = css`
  ${mq[0]} {
    flex-shrink: 0;
    width: 50%;
    max-width: 320px;
  }
`;

export const headingCSS = css`
  font-size: 16px;
  font-weight: 700;
`;

export const headerCSS = css`
  display: flex;
  align-items: center;
  ${headingCSS};
  margin: 0 0 12px;
  svg {
    margin-right: 8px;
  }
`;

export const buttonCSS = css`
  background-color: ${THEME.color.aqua};
  border-color: ${THEME.color.aqua};
  color: ${THEME.color.black};
  margin-top: 24px;
  &:focus,
  &:active,
  &:hover {
    background-color: ${THEME.color.white};
    border-color: ${THEME.color.aqua};
    color: ${THEME.color.black};
  }
`;

export const checkListCSS = css`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const checkListItemCSS = css`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  line-height: 20px;
  gap: 16px
`;

export const checkListValueCSS = css`
  display: flex;
  align-items: center;
  svg {
    margin-left: 8px;
    color: ${THEME.color.aqua};
  }
`;
