import { type SerializedStyles, css } from '@emotion/react';

import { mq } from '../../styles/media-queries.ts';
import type { GridWidth } from '../../types/form.type.ts';

import THEME from '../../styles/theme.ts';

const disabledCSS = css`
  opacity: 0.5;
  pointer-events: none;
`;

export function gridWidthCSS(gridWidth: GridWidth): SerializedStyles {
  return css`
  flex-basis: ${(gridWidth / 12) * 100}%;
  padding-right: ${THEME.size.inputHorizontalSpacing}px;
`;
}

export function containerCSS(isDisabled?: boolean, gridWidth?: GridWidth | null): SerializedStyles {
  return css`
  display: block;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 20px;
  ${isDisabled && disabledCSS};

  ${mq[0]} {
    margin-bottom: 20px;
    ${gridWidth && gridWidthCSS(gridWidth)};
  }
`;
}

export const labelCSS = css`
  margin-bottom: 4px;
`;

export const errorCSS = css`
  margin-top: 4px;
`;

export const innerWrapperCSS = css`
  border: 1px solid ${THEME.color.black};
  border-radius: ${THEME.size.borderRadius}px;
  padding: 16px 20px;
`;

export const labelWithIconCSS = css`
  font-weight: 700;
  margin-left: 12px;
`;

export const descriptionWithIconCSS = css`
  margin-bottom: 4px;
  line-height: 20px;
`;

export const iconLabelWrapperCSS = css`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;
