import { css } from '@emotion/react';
import { mq } from '../../../styles/media-queries';

export const titleCSS = css`
  display: flex;
  align-items: center;
  font-size: 20px;
  line-height: 32px;
  font-weight: 700;
  gap: 8px;

  ${mq[0]} {
    font-size: 24px;
  }
`;

export const addressCSS = css`
  font-weight: 500;
  margin-top: 9px;
`;

export const chartCSS = css`
  width: 100%;
  max-width: 500px;
  height: 200px;
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 48px;

  img {
    width: 100%;
    height: 100%;
    position: absolute;
  }
`;

export const notificationCSS = css`
  z-index: 1;
  text-align: center;
  max-width: 340px;
  margin: auto;

  h3 {
    margin: 0;
    font-size: 16px
  }

  p {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
  }
`;
