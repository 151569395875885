import { css } from '@emotion/react';

import { mq } from '../../styles/media-queries';
import THEME from '../../styles/theme';

export function containerCSS(backgroundColor: string, isTextCard: boolean) {
  return css`
    background-color: ${backgroundColor};
    border-radius: ${isTextCard ? '8px' : ''};
    padding: 12px;
    margin: ${isTextCard ? '12px' : '0'};
    border: ${isTextCard ? `1px solid ${THEME.color.gray10}` : 'none'};
    position: relative;
    ${mq[0]} {
      padding: ${isTextCard ? '24px' : '40px'};
      margin: 0;
      border-radius: 8px;
    }
  `;
}
