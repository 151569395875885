import { type FC, useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import formatUtil from '../../../utils/format.util.ts';
import { futureMarkCSS, highlightedDataCSS, highlightedDataWrapperCSS, markCSS } from '../../../styles/chart.style.ts';
import useSize from '../../../hooks/use-size.ts';
import type { TransformedReplacementCostDataItem } from '../../../types/history.type.ts';
import {
  chartFutureDataBorderColor,
  chartFutureDataColor,
  chartMainColors,
  formatSuffix,
  getLatestYearObject,
  setBarAttributes,
} from '../../../utils/chart.util.ts';
import THEME from '../../../styles/theme.ts';
import type { ReplacementCostGraphProps } from './ReplacementCostGraph.type';
import { chartCSS, hoverBgCSS, invisibleHoverBgCSS, xTickCSS, yTickCSS } from './ReplacementCostGraph.style';

const currentYear = new Date().getFullYear().toString();

const ReplacementCostGraph: FC<ReplacementCostGraphProps> = ({ data, hidePreview = true }) => {
  const svgRef = useRef(null);
  const size = useSize(svgRef);

  const [selectedData, setSelectedData] = useState(getLatestYearObject<TransformedReplacementCostDataItem>(data));

  useEffect(() => {
    if (!size?.width)
      return;

    const margin = { top: 20, bottom: 30, left: 50 };
    const width = size.width - margin.left;
    const height = 243 - margin.top - margin.bottom;

    // clear the previous content for rerenders
    d3.select(svgRef.current).selectAll('*').remove();

    const svg = d3.select(svgRef.current)
      .attr('width', width)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    const x0 = d3.scaleBand()
      .domain(data.map(d => d.year))
      .range([0, width])
      .padding(0.4);

    const flatValues = data.flatMap(d =>
      d.values.flatMap(val =>
        typeof val === 'number' ? val : [val.min, val.max],
      ),
    );

    const [minValue, maxValue] = [
      d3.min(flatValues)!,
      d3.max(flatValues)!,
    ];

    const halfMinValue = minValue / 2;

    const tickInterval = (maxValue - halfMinValue) / 4;
    const roundedTickInterval = Math.round(tickInterval / 10) * 10;

    const customTicks = d3.range(halfMinValue, maxValue, roundedTickInterval);

    const y = d3.scaleLinear()
      .domain([halfMinValue, maxValue])
      .range([height, 0])
      .nice();

    const yAxis = svg.append('g')
      .call(
        d3.axisLeft(y)
          .tickFormat(d => `$${d}`)
          .tickValues(customTicks)
          .tickSize(-width)
          .tickPadding(10),
      );

    yAxis.select('.domain').remove();
    yAxis.selectAll('.tick line')
      .attr('class', 'y-tick')
      .attr('stroke', (_, i) => (i === 0 ? '#333' : '#E6E8EB'));

    // initial position of hoverBg on the last bar
    const lastBarX = x0(data[data.length - 1].year)!;

    const hoverBg = svg.append('rect')
      .attr('class', 'hover-bg')
      .attr('height', height + 11)
      .attr('width', x0.bandwidth())
      .attr('x', lastBarX)
      .attr('y', -13);

    const groups = svg.selectAll('g.layer')
      .data(data)
      .enter().append('g')
      .attr('transform', d => `translate(${x0(d.year)},0)`)
      .each(function (d) {
        d3.select(this).append('rect')
          .attr('class', 'invisible-hover-bg')
          .attr('width', x0.bandwidth())
          .attr('height', height)
          .on('mouseenter touchstart', (_) => {
            const newX = x0(d.year)!;

            hoverBg.transition()
              .duration(500)
              .attr('x', newX);

            setSelectedData(d);
          });
      });

    const yearPositions = data.map(d => x0(d.year)!);
    const chartWidth = x0.range()[1]; // This gives the end of the chart

    // Function to calculate the width of the bar
    const calculateBarWidth = (year: string) => {
      const currentIndex = yearPositions.indexOf(x0(year)!);
      const nextIndex = currentIndex + 1;

      if (nextIndex < yearPositions.length) {
        return yearPositions[nextIndex] - yearPositions[currentIndex];
      }
      // If no next bar, extend to the end of the chart
      return chartWidth - yearPositions[currentIndex] - 1;
    };

    // Apply the dynamic width calculation
    groups.selectAll('rect.value-bar')
      .data(d => d.values.map((value, i) => ({ key: i, value: value as { min: number; max: number }, year: d.year })))
      .enter()
      .filter(d => d.key === 0)
      .append('rect')
      .attr('class', 'value-bar')
      .attr('y', d => y(d.value.max)) // Initial y-position
      .attr('height', d => y(d.value.min) - y(d.value.max)) // Start with height 0 for animation
      .attr('fill', d => (d.year > currentYear
        ? chartFutureDataColor(`${d.key}`)
        : chartMainColors(`${d.key}`)) as string)
      .attr('stroke', d => (d.year > currentYear ? chartFutureDataBorderColor('0') : 'none') as string)
      .attr('stroke-dasharray', d => (d.year > currentYear ? '4,2' : 'none'))
      .attr('stroke-width', 2)
      .transition()
      .duration(1000)
      .ease(d3.easeCubicOut)
      .attr('width', d => calculateBarWidth(d.year))
      .attr('y', d => y(d.value.max));

    groups.selectAll('rect.bar')
      .data(d => d.values.map((value, i) => ({ key: i, value, year: d.year })))
      .enter().append('rect')
      .filter(d => d.key === 1)
      .call(setBarAttributes, x0, y, height, currentYear, 0.4);

    const xAxis = svg.append('g')
      .attr('transform', `translate(0,${height})`)
      .call(
        d3.axisBottom(x0)
          .tickSize(16)
          .tickPadding(1)
          .tickFormat(year => `${year}${formatSuffix(+year, +currentYear)}`),
      );

    xAxis.selectAll('.tick line')
      .attr('class', 'x-tick')
      .attr('transform', 'translate(0, -10)');

    xAxis.select('.domain').remove();
  }, [size?.width, data]);

  return (
    <>
      {!hidePreview
      && (
        <div css={highlightedDataWrapperCSS}>
          <div css={highlightedDataCSS}>
            <div>
              <div css={selectedData.year > currentYear ? futureMarkCSS(true) : markCSS(THEME.color.violet87)} />
              <p>Suggested <span>{selectedData.year}</span> coverage</p>
            </div>

            <p>
              ${formatUtil.money(selectedData.values[0].min)}-${formatUtil.money(selectedData.values[0].max)}
              {formatSuffix(+selectedData.year, +currentYear)}
            </p>
          </div>

          <div css={highlightedDataCSS}>
            <div>
              <div css={selectedData.year > currentYear ? futureMarkCSS(false) : markCSS(THEME.color.azure62)} />
              <p>Your <span>{selectedData.year}</span> coverage</p>
            </div>

            <p>
              ${formatUtil.money(selectedData.values[1])}
              {formatSuffix(+selectedData.year, +currentYear)}
            </p>
          </div>
        </div>
      )}

      <svg
        ref={svgRef}
        css={[chartCSS, yTickCSS, xTickCSS, hoverBgCSS, invisibleHoverBgCSS]}
      />
    </>

  );
};

export default ReplacementCostGraph;
