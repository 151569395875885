import type { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PageContainer from '../../components/PageContainer/PageContainer';
import { pageHeadingCSS } from '../../styles/layout.style';
import homeIcon from '../../assets/svg/home.svg';
import carIcon from '../../assets/svg/car.svg';
// import leafIcon from '../../assets/svg/leaf.svg';
import shieldIcon from '../../assets/svg/checkbox-shield.svg';

import useSettingsQuery from '../../api/settings/use-settings-query';
import type { DefaultRouteParams } from '../../types/route-params.type';
import useDpNavigate from '../../hooks/use-dp-navigate';
import { SEGMENT } from '../../constants/analytics.ts';
import useTrackPage from '../../hooks/use-track-page.ts';
import useSegment from '../../hooks/use-segment.ts';
import { DpCta } from '../../types/digital-profile.type.ts';
import ROUTES from '../../constants/routes.ts';
import PolicyCard from './PolicyCard/PolicyCard';
import { policiesWrapperCSS } from './index.style';

const PoliciesLayout: FC = () => {
  const { gid } = useParams() as DefaultRouteParams;
  const { data: settingsData } = useSettingsQuery();
  const { track } = useSegment();

  const navigateToDp = useDpNavigate();
  const navigate = useNavigate();

  useTrackPage(SEGMENT.pages.policies);

  const onActionClick = async (variant: 'home' | 'auto' | 'other') => {
    track(SEGMENT.events.addNewPolicyClicked, {
      policy_variant: variant,
    });

    switch (variant) {
      case 'home':
        navigateToDp(DpCta.Quotes);
        break;
      case 'auto':
        if (settingsData?.matic_auto_flow_url) {
          window.location.href = settingsData?.matic_auto_flow_url;
        }
        break;
      case 'other':
        navigate(ROUTES.scheduleCall.replace(':gid', gid));
        break;
    }
  };

  return (
    <PageContainer pageKey={SEGMENT.pages.policies}>
      <h1 css={pageHeadingCSS}>
        My Policies
      </h1>

      <div css={policiesWrapperCSS}>
        <PolicyCard
          icon={{ src: homeIcon }}
          title="Add a home policy"
          onActionClick={() => onActionClick('home')}
        />
        <PolicyCard
          icon={{ src: carIcon }}
          title="Add an auto policy"
          onActionClick={() => onActionClick('auto')}
        />
        {/* <PolicyCard icon={{ src: leafIcon }} title="Add a life policy" onActionClick={onActionClick} /> */}
        <PolicyCard
          icon={{ src: shieldIcon }}
          title="Add another policy"
          onActionClick={() => onActionClick('other')}
          buttonText="Schedule a Call"
        />
      </div>

    </PageContainer>
  );
};

export default PoliciesLayout;
