import type { SerializedStyles } from '@emotion/react';

export enum SuggestedQuoteState {
  Ready = 'ready',
  QuotingInProgress = 'quoting_in_progress',
  NoDataForQuoting = 'no_data_for_quoting',
  NoQuotes = 'no_quotes',
  Error = 'error',
  Unknown = 'unknown',
}

export interface SuggestedPolicyProps {
  customCSS?: SerializedStyles;
  page: string;
  onShowMoreQuotesClick?: () => void;
}
