import { createSearchParams, useNavigate, useParams } from 'react-router-dom';
import type { DefaultRouteParams } from '../types/route-params.type';
import ROUTES from '../constants/routes';
import type { DpCta } from '../types/digital-profile.type';

export default function useDpNavigate() {
  const { gid } = useParams() as DefaultRouteParams;
  const navigate = useNavigate();

  const navigateToProfileIntro = (cta: DpCta) => {
    const path = ROUTES.digitalProfileIntro.replace(':gid', gid);
    const searchParams = createSearchParams({ cta }).toString();

    navigate({ pathname: path, search: searchParams });
  };

  return navigateToProfileIntro;
}
