const BASE_URL = '/api/frontend/v1';
export const RATE_REVIEWS = `${BASE_URL}/rate_reviews`;
export const RATE_REVIEWS_SUBSCRIPTIONS = `${BASE_URL}/rate_review_subscriptions`;

/** TODO: Move to v2 api */
export const SETTINGS = `${BASE_URL}/settings`;

/** TODO: Remove v1 constants */

const BASE = '/api/frontend/v2';

const API_PATH = {
  createSession: `${BASE}/sessions`,
  sessionCurrent: `${BASE}/sessions/current`,
  sessionRefresh: `${BASE}/sessions/refresh`,
  magicLink: `${BASE}/sessions/magic_link`,
  user: `${BASE}/users/:gid`,
  verifyUser: `${BASE}/users/:gid/verify`,
  scores: `${BASE}/users/:gid/scores/:type`,
  quotes: `${BASE}/users/:gid/quotes`,
  suggestedQuote: `${BASE}/users/:gid/quotes/suggested`,
  customerChoice: `${BASE}/users/:gid/quotes/:quote_gid/customer_choice`,
  availableCarriers: `${BASE}/users/:gid/carriers`,
  digitalProfile() {
    return `${this.user}/profile`;
  },
  recentPolicy() {
    return `${this.user}/recent_policy`;
  },
  leads: `${BASE}/users/:gid/leads`,
  disclosures: `${BASE}/disclosures`,
  proposals: `${BASE}/proposals?person_gid=:person_gid&engagement_gid=:engagement_gid`,
  proposal: `${BASE}/proposals/:gid`,
  trackProposalOpened: `${BASE}/proposals/:gid/track_opened`,
  proposalUserPresence: `${BASE}/proposals/:gid/user_presence`,
  personAssets: `${BASE}/people/:gid/assets`,
  relatedPeople: `${BASE}/people/:gid/related_people`,
  agentContactInfo: `${BASE}/agents/:gid/contact_info`,
  soldCustomersCount: `${BASE}/agents/:gid/sold_customers_count`,
  personLoans: `${BASE}/people/:gid/loans`,
  maticContent: `https://matic.com/wp-json/wp/v2/posts?_embed=wp:featuredmedia,
  wp:term&_fields=title,link,excerpt,_links,_embedded&per_page=30&categories=:categoryId`,
  savings: `${BASE}/people/:person_gid/policies/savings`,
  publicRecentPolicy: `${BASE}/people/:person_gid/policies/recent`,
  history() {
    return `${this.user}/history`;
  },
  historyPremium() {
    return `${this.history()}/premium`;
  },
  replacementCostPremium() {
    return `${this.history()}/replacement_cost`;
  },
};

export default API_PATH;
