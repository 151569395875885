import type { FC } from 'react';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';

import useAgentContactInfoQuery from '../../api/proposal/use-agent-contact-info-query.ts';
import usePersonAssetsQuery from '../../api/proposal/use-person-assets-query.ts';
import useProposalQuery from '../../api/proposal/use-proposal-query.ts';
import MaticLogoIcon from '../../assets/svg/matic-logo.svg?react';
import WarningIcon from '../../assets/svg/warning.svg?react';
import ProposalCard from '../../components/ProposalCard/ProposalCard.tsx';
import ROUTES from '../../constants/routes.ts';
import Container from '../../shared/components/Container/Container.tsx';
import FullPageLoader from '../../shared/components/FullPageLoader/FullPageLoader.tsx';
import { InsurableInterest } from '../../types/quote.type.ts';
import type { DefaultProposalRouteParams } from '../../types/route-params.type.ts';
import { getStateNameByCode } from '../../utils/address.ts';
import { isMoreThanOneWeekOld } from '../../utils/date.util.ts';
import THEME from '../../styles/theme.ts';
import AutoQuoteAd from './AutoQuoteAd/AutoQuoteAd.tsx';
import ProposalAd from './ProposalAd/ProposalAd.tsx';
import ProposalAutoQuoteInfo from './ProposalAutoQuoteInfo/ProposalAutoQuoteInfo.tsx';
import ProposalFooter from './ProposalFooter/ProposalFooter.tsx';
import ProposalHeader from './ProposalHeader/ProposalHeader.tsx';
import ProposalHomeQuoteInfo from './ProposalHomeQuoteInfo/ProposalHomeQuoteInfo.tsx';
import ProposalPresenceTracker from './ProposalPresenceTracker/ProposalPresenceTracker.tsx';
import ProposalsHistory from './ProposalsHistory/ProposalsHistory.tsx';
import ProposalSummary from './ProposalSummary/ProposalSummary.tsx';

import { containerCSS, expiredBannerCSS, expiredTextCSS, logoBoxCSS, quoteDisclaimerTextCSS } from './index.style.ts';

const ProposalLayout: FC = () => {
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const isOpenedFromAmp = searchParams.get('referrer') === 'amp';
  const navigate = useNavigate();

  const { proposal_gid: proposalGid } = useParams() as DefaultProposalRouteParams;

  const { data: proposal, refetch: refetchProposal, isError } = useProposalQuery({ proposalGid });

  const personGid = proposal?.person_gid;
  const engagementGid = proposal?.engagement_gid;

  const homeQuotes = proposal?.proposal_options[0]?.quotes.filter(
    ({ insurable_interest }) => insurable_interest === InsurableInterest.RealProperty,
  );
  const autoQuotes = proposal?.proposal_options[0]?.quotes.filter(
    ({ insurable_interest }) => insurable_interest === InsurableInterest.Vehicle,
  );

  const { data: agentContactInfo } = useAgentContactInfoQuery({ agentGid: proposal?.agency_user_gid });
  const { data: assets } = usePersonAssetsQuery({ personGid });
  const state = getStateNameByCode(assets?.homes[0]?.address.state || assets?.vehicles[0]?.address.state);
  const isProposalExpired = isMoreThanOneWeekOld(proposal?.created_at);

  if (isError && !proposal)
    return <Navigate to={ROUTES.problem} replace />;

  if (!proposal || !agentContactInfo || !state || !personGid) {
    return <FullPageLoader />;
  }

  return (
    <Container fullscreen customCSS={containerCSS(isOpenedFromAmp)}>
      <MaticLogoIcon height={26} />
      {isProposalExpired && (
        <div css={expiredBannerCSS}>
          <WarningIcon width={24} />
          <p css={expiredTextCSS}>
            The proposal pricing may not be current. Insurance rates change frequently, so quoted prices might differ
            from current offerings. For accuracy, review updated rates and coverage with your agent to make decisions
            based on current information.
          </p>
        </div>
      )}
      {!isOpenedFromAmp && <ProposalPresenceTracker proposal={proposal} />}
      <ProposalHeader
        isOpenedFromAmp={isOpenedFromAmp}
        proposal={proposal}
        refetchProposal={() => refetchProposal()}
        agentContactInfo={agentContactInfo}
        state={state}
      />
      {!isOpenedFromAmp && engagementGid && (
        <ProposalsHistory
          personGid={personGid}
          engagementGid={engagementGid}
          openedProposalGid={proposalGid}
          onOpenProposal={proposalGid => navigate(`/proposal/${proposalGid}${search}`)}
        />
      )}
      {homeQuotes?.map(
        quote => quote && <ProposalHomeQuoteInfo quote={quote} personGid={personGid} key={quote.gid} />,
      )}
      {autoQuotes && autoQuotes.length > 0
        ? (
            autoQuotes.map(
              quote => quote && <ProposalAutoQuoteInfo quote={quote} personGid={personGid} key={quote.gid} />,
            )
          )
        : (
            <AutoQuoteAd agentContactInfo={agentContactInfo} isProposalExpired={isProposalExpired} />
          )}
      <ProposalSummary
        homeQuotes={homeQuotes}
        autoQuotes={autoQuotes}
        allQuotes={proposal.proposal_options[0].quotes}
        personGid={personGid}
      />
      <ProposalAd agentContactInfo={agentContactInfo} isProposalExpired={isProposalExpired} />
      <ProposalFooter />
      <ProposalCard backgroundColor={THEME.color.gray5}>
        <p css={quoteDisclaimerTextCSS}>
          Quotes are estimates only. Quotes are subject to change without notice. Your actual rate, payment and coverage
          may be different. Quotes do not constitute a final offer of insurance, nor is any contract, agreement, or
          insurance coverage implied, formed or bound by the provision of this quote. Insurability, final insurance
          premium quotes and an offer of insurance is determined by the insurance company providing your insurance
          policy.
        </p>
        <div css={logoBoxCSS}>
          <MaticLogoIcon height={26} />
        </div>
      </ProposalCard>
    </Container>
  );
};

export default ProposalLayout;
