import type { FC } from 'react';
import { Global } from '@emotion/react';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import AuthGuard from './components/AuthGuard/AuthGuard.tsx';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import SessionRoot from './components/SessionRoot/SessionRoot.tsx';
import ROUTES, { ERROR, RATE_REVIEW, RATE_REVIEW_CONFIRMATION } from './constants/routes';
import Authenticate from './layouts_deprecated/Authenticate/Authenticate.tsx';
import Error from './layouts_deprecated/Error/Error.tsx';
import RateReview from './layouts_deprecated/RateReview/RateReview.tsx';
import RateReviewConfirmation from './layouts_deprecated/RateReviewConfirmation/RateReviewConfirmation.tsx';
import AccountLayout from './pages/account';
import CheckEmailLayout from './pages/check-email';
import InsightsLayout from './pages/insights';
import LinkExpiredLayout from './pages/link-expired';
import ProposalLayout from './pages/proposal/index.tsx';
import RateReviewLayout from './pages/rate-review/index.tsx';
import ReplacementCostLayout from './pages/replacement-cost/index.tsx';
import SignInLayout from './pages/sign-in';
import SnapshotDetailsLayout from './pages/snapshot-details';
import VerifyLayout from './pages/verify';

import normalizeCSS, { globalCSS } from './styles/global.style.ts';
import CarrierOptionsLayout from './pages/carrier-options/index.tsx';
import DpIntroLayout from './pages/dp-intro/index.tsx';
import ScheduleCallLayout from './pages/schedule-call/index.tsx';
import { ActionCableContext, setupCableConsumer } from './utils/actioncable.ts';
import PoliciesLayout from './pages/policies/index.tsx';
import useSettingsQuery from './api/settings/use-settings-query.ts';
import useAnalytics from './hooks/use-analytics.ts';
import AuthRoot from './components/AuthRoot/AuthRoot.tsx';

const App: FC = () => {
  const { data: settingsData } = useSettingsQuery();
  useAnalytics({ segment: settingsData?.segment });

  if (!settingsData) {
    return null;
  }

  return (
    <>
      <Global styles={normalizeCSS} />
      <ActionCableContext.Provider value={setupCableConsumer()}>
        <BrowserRouter>
          <ScrollToTop />
          <Routes>
            <Route path={ROUTES.authenticate} element={<Authenticate />} />

            <Route path={ROUTES.sessionBase} element={<SessionRoot />}>
              <Route
                path={ROUTES.insights}
                element={(
                  <AuthGuard>
                    <InsightsLayout />
                  </AuthGuard>
                )}
              />
              <Route
                path={ROUTES.rateReview}
                element={(
                  <AuthGuard>
                    <RateReviewLayout />
                  </AuthGuard>
                )}
              />
              <Route
                path={ROUTES.replacementCost}
                element={(
                  <AuthGuard>
                    <ReplacementCostLayout />
                  </AuthGuard>
                )}
              />
              <Route
                path={ROUTES.carrierOptions}
                element={(
                  <AuthGuard>
                    <CarrierOptionsLayout />
                  </AuthGuard>
                )}
              />
              <Route
                path={ROUTES.digitalProfileIntro}
                element={(
                  <AuthGuard>
                    <DpIntroLayout />
                  </AuthGuard>
                )}
              />
              <Route
                path={ROUTES.policies}
                element={(
                  <AuthGuard>
                    <PoliciesLayout />
                  </AuthGuard>
                )}
              />
              <Route
                path={ROUTES.snapshotDetails}
                element={(
                  <AuthGuard>
                    <SnapshotDetailsLayout />
                  </AuthGuard>
                )}
              />
              <Route
                path={ROUTES.scheduleCall}
                element={(
                  <AuthGuard>
                    <ScheduleCallLayout />
                  </AuthGuard>
                )}
              />
              <Route
                path={ROUTES.account}
                element={(
                  <AuthGuard>
                    <AccountLayout />
                  </AuthGuard>
                )}
              />
              <Route
                path={ROUTES.verify}
                element={(
                  <AuthGuard skipVerification>
                    <VerifyLayout />
                  </AuthGuard>
                )}
              />

            </Route>

            <Route path={ROUTES.auth} element={<AuthRoot />}>
              <Route
                path={ROUTES.linkExpired}
                element={<LinkExpiredLayout />}
              />
              <Route
                path={ROUTES.checkEmail}
                element={<CheckEmailLayout />}
              />
              <Route
                path={ROUTES.signIn}
                element={<SignInLayout />}
              />
            </Route>

            <Route
              path={ROUTES.proposal}
              element={(
                <>
                  <ProposalLayout />
                  <Global styles={globalCSS} />
                </>
              )}
            />
            <Route path={ROUTES.problem} element={<Error />} />

            {/** v1 routes. TODO: Remove after v2 release */}
            <Route path={RATE_REVIEW_CONFIRMATION} element={<RateReviewConfirmation />} />
            <Route path={RATE_REVIEW} element={<RateReview />} />
            <Route path={ERROR} element={<Error />} />
          </Routes>
        </BrowserRouter>
      </ActionCableContext.Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </>
  );
};

export default App;
