import THEME from '../../../styles/theme.ts';

export const GAUGE_PALETTE = [
  THEME.color.errorRed,
  THEME.color.orangePrimary,
  THEME.color.primarySun,
  THEME.color.aqua,
  THEME.color.positiveGreen27,
];

export const GAUGE_LABELS_POSITIVE = ['Poor', 'Moderate', 'Good', 'Very Good', 'Excellent'];

export const GAUGE_LABELS_NEGATIVE = ['Low', 'Mild', 'Moderate', 'High', 'Very High'];

const STEPS = GAUGE_LABELS_POSITIVE.length;

export function getGaugeColor(score: number, scoreMax: number, palette: string[]) {
  if (score === 0) {
    return palette[0];
  }
  const step = scoreMax / STEPS;
  const index = Math.floor((score - 1) / step);
  return palette[index];
}

export function getGaugeLabel(score: number, scoreMax: number, labels: string[]) {
  if (score === 0) {
    return labels[0];
  }
  const step = scoreMax / STEPS;
  const index = Math.floor((score - 1) / step);
  return labels[index];
}
