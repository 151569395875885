import type { QuotesResponse, SuggestedQuoteResponse } from '../../types/quote.type.ts';
import { QuotingStatus } from '../../types/quote.type.ts';
import type { UserResponse } from '../../types/user.type.ts';
import { SuggestedQuoteState } from './SuggestedPolicy.type.ts';

export function getQuoteState(
  quotingStatus?: QuotingStatus,
  userData?: UserResponse,
  quotesData?: QuotesResponse,
  suggestedQuoteData?: SuggestedQuoteResponse,
  hasError?: boolean,
): SuggestedQuoteState {
  const dpCompleted = userData?.user.profile_completed_at;

  switch (true) {
    case hasError:
      return SuggestedQuoteState.Error;
    case !userData:
    case dpCompleted && !quotesData:
    case quotingStatus === QuotingStatus.Finished && !suggestedQuoteData:
      return SuggestedQuoteState.Unknown;
    case !dpCompleted || !quotingStatus:
      return SuggestedQuoteState.NoDataForQuoting;
    case Boolean(dpCompleted && suggestedQuoteData?.quote):
      return SuggestedQuoteState.Ready;
    case quotingStatus === QuotingStatus.Pending:
      return SuggestedQuoteState.QuotingInProgress;
    case quotingStatus === QuotingStatus.Finished && !suggestedQuoteData?.quote:
      return SuggestedQuoteState.NoQuotes;
    default:
      return SuggestedQuoteState.Unknown;
  }
}
