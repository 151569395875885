import { css } from '@emotion/react';

export const chartCSS = css`
  margin-top: 24px;
  width: 100%;
`;

export const yTickCSS = css`
  [class='y-tick'] {
    stroke-width: 2px;
    + text {
      font-size: 12px;
    }
  }
`;

export const xTickCSS = css`
  [class='x-tick'] {
    stroke: #333;
    stroke-width: 2px;

    + text {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
  }
`;

export const hoverBgCSS = css`
  [class='hover-bg'] {
    fill: #F8F8F8;
    opacity: 0.55;
    stroke: #E6E6E6;
    stroke-width: 2px;
  }
`;

export const invisibleHoverBgCSS = css`
  [class='invisible-hover-bg'] {
    fill: transparent;
    pointer-events: all;
  }
`;
