import { type FC, useEffect, useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import useMutateUser from '../../../api/user/use-mutate-user.ts';
import useUserQuery from '../../../api/user/use-user-query.ts';
import Question from '../../../components/Question/Question.tsx';
import Button from '../../../shared/components/Button/Button.tsx';
import type { DefaultRouteParams } from '../../../types/route-params.type.ts';
import { scrollToError } from '../../../utils/form.util.ts';
import Notification from '../../../shared/components/Notification/Notification.tsx';
import useSegment from '../../../hooks/use-segment.ts';
import { SEGMENT } from '../../../constants/analytics.ts';
import { USER_FORM_SCHEMA, type UserFormProps, type UserFormState } from './UserForm.type.ts';
import { userDataHasChanges } from './UserForm.util.ts';

import { actionButtonsCSS, formContainerCSS } from './UserForm.style.ts';

const UserForm: FC<UserFormProps> = ({ customCSS }) => {
  const { gid } = useParams() as DefaultRouteParams;
  const { data: userData } = useUserQuery(gid);
  const { mutateAsync: updateUser, isPending: isUserUpdatePending } = useMutateUser(gid);
  const [updateNotification, setShowNotification] = useState<string | null>();
  const { track } = useSegment();

  const methods = useForm<UserFormState>({
    shouldUnregister: false,
    shouldFocusError: false,
    mode: 'onTouched',
  });

  const formData = methods.watch();

  const isDataChanged = userDataHasChanges(formData, userData?.user || {});

  useEffect(() => {
    if (userData) {
      methods.reset({
        first_name: userData.user.first_name,
        last_name: userData.user.last_name,
        email: userData.user.email,
        phone: userData.user.phone,
        date_of_birth: userData.user.date_of_birth,
      });
    }
  }, [methods, userData]);

  const onFormReset = () => {
    methods.reset({
      first_name: userData?.user.first_name,
      last_name: userData?.user.last_name,
      email: userData?.user.email,
      phone: userData?.user.phone,
      date_of_birth: userData?.user.date_of_birth,
    });
  };

  const onFormSubmit = async (data: UserFormState) => {
    if (isUserUpdatePending || !isDataChanged) {
      return;
    }
    try {
      await updateUser(data);
      setShowNotification('Your Profile has been updated');
      track(SEGMENT.events.accountProfileUpdated, { status: 'success' });
    }
    catch (error) {
      // TODO: Handle error. Show error notification
      console.error(error);
      setShowNotification('An error occurred while updating your profile');
      track(SEGMENT.events.accountProfileUpdated, { status: 'failed' });
    }
  };

  return (
    <div css={customCSS}>
      <Notification
        open={!!updateNotification}
        message={updateNotification || ''}
        onClose={() => setShowNotification(null)}
      />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onFormSubmit, scrollToError)}>
          <div css={formContainerCSS}>
            {USER_FORM_SCHEMA.map((questionProps) => {
              return <Question key={questionProps.questionKey} {...questionProps} />;
            })}
          </div>
          <div css={actionButtonsCSS}>
            <Button variant="secondary" disabled={!isDataChanged || isUserUpdatePending} onClick={onFormReset}>
              Discard Changes
            </Button>
            <Button type="submit" isLoading={isUserUpdatePending}>
              Update Profile
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default UserForm;
