import type { FC } from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../../shared/components/Button/Button';
import QuoteItem from '../QuoteItem/QuoteItem.tsx';
import useAvailableCarriersQuery from '../../../api/available-carriers/use-available-carriers-query.ts';
import type { DefaultRouteParams } from '../../../types/route-params.type.ts';
import useQuotesQuery from '../../../api/quotes/use-quotes-query.ts';
import ListLoadingSkeleton from '../../../components/ListLoadingSkeleton/ListLoadingSkeleton.tsx';
import { QuotingStatus } from '../../../types/quote.type.ts';
import ROUTES from '../../../constants/routes.ts';
import useDpNavigate from '../../../hooks/use-dp-navigate.ts';
import { DpCta } from '../../../types/digital-profile.type.ts';
import useUserQuery from '../../../api/user/use-user-query.ts';
import { QuoteStatus } from '../QuoteItem/QuoteItem.type.ts';
import useMutateCustomerChoice from '../../../api/quote/use-mutate-customer-choice.ts';
import useSegment from '../../../hooks/use-segment.ts';
import { SEGMENT } from '../../../constants/analytics.ts';
import useMutateLeadCreation from '../../../api/lead/use-mutate-lead-creation.ts';
import { buttonCSS, listContainerCSS } from './CarrierOptionsList.style.ts';
import type { CarrierOptionsListProps } from './CarrierOptionsList.type.ts';
import {
  addQuoteData,
  getFullCarriersList,
  getQuotesMap,
  sortCarriersByPremium,
  sortCarriersByQuoteStatus,
} from './CarrierOptionsList.util.ts';

const CarrierOptionsList: FC<CarrierOptionsListProps> = ({ customCSS }) => {
  const { gid } = useParams() as DefaultRouteParams;
  const navigateToDp = useDpNavigate();
  const { data: userData } = useUserQuery(gid);
  const { data: availableCarriersData } = useAvailableCarriersQuery(gid);
  const { data: quotesData } = useQuotesQuery(gid, !!userData?.user.profile_completed_at);
  const quotesMap = getQuotesMap(quotesData?.quotes);
  const { mutateAsync: submitCustomerChoice } = useMutateCustomerChoice(gid);
  const { mutateAsync: createLead } = useMutateLeadCreation(gid);

  const navigate = useNavigate();
  const [maxOptions, setMaxOptions] = useState<number | null>(12);
  const [updatingQuoteId, setUpdatingQuoteId] = useState<string | null>(null);

  const { track } = useSegment();

  const quotingStatus = quotesData?.quotes_request_status;
  const isDataLoading = !userData || !availableCarriersData || (!quotesData && userData.user.profile_completed_at);

  const allOptions = getFullCarriersList(availableCarriersData, quotesData)
    .map(carrier => addQuoteData(carrier, quotesMap, !!userData?.user.profile_completed_at, quotingStatus));
  const shouldRenderManualOptions = quotingStatus === QuotingStatus.Finished && allOptions
    .every(({ quote }) => quote.status === QuoteStatus.Failed || quote.status === QuoteStatus.Manual);
  const optionsToRender = shouldRenderManualOptions
    ? allOptions
    : allOptions.filter(({ quote }) => quote.status !== QuoteStatus.Manual);

  const handleShowMoreClick = () => {
    setMaxOptions(null);
  };

  const handleQuoteUnlock = () => {
    track(SEGMENT.events.unlockPortalDataRequested, {
      page: SEGMENT.pages.carrierOptions,
      location: SEGMENT.locations.carrierOptionsList,
    });
    navigateToDp(DpCta.Quotes);
  };

  const handleQuoteChoose = async (quoteGid: string) => {
    const quote = quotesData?.quotes.find(q => q.gid === quoteGid);

    setUpdatingQuoteId(quoteGid);
    track(SEGMENT.events.quoteSelected, {
      page: SEGMENT.pages.carrierOptions,
      location: SEGMENT.locations.carrierOptionsList,
      quote_gid: quoteGid,
      carrier_key: quote?.carrier.key,
      policy_type: quote?.policy_type,
      premium_value: Number(quote?.premium),
    });
    await createLead();
    await submitCustomerChoice(quoteGid);
    setUpdatingQuoteId(null);
    navigate(ROUTES.scheduleCall.replace(':gid', gid));
  };

  if (isDataLoading) {
    return (
      <ListLoadingSkeleton customCSS={customCSS} numberOfItems={9} />
    );
  }

  return (
    <div css={customCSS}>
      <div css={listContainerCSS}>
        {optionsToRender
          .sort((a, b) => sortCarriersByPremium(a.quote.premium, b.quote.premium))
          .sort((a, b) => sortCarriersByQuoteStatus(a.quote.status, b.quote.status))
          .slice(0, maxOptions || availableCarriersData.carriers.length)
          .map(({ carrier, quote }) => {
            return (
              <QuoteItem
                key={carrier.key}
                carrier={carrier}
                quote={quote}
                isUpdating={quote.gid === updatingQuoteId}
                onQuoteUnlock={handleQuoteUnlock}
                onQuoteChoose={handleQuoteChoose}
              />
            );
          })}
      </div>

      {Boolean(maxOptions && maxOptions < optionsToRender.length) && (
        <Button customCSS={buttonCSS} variant="secondary" fullWidth onClick={handleShowMoreClick}>
          View full carrier list
        </Button>
      )}

    </div>
  );
};

export default CarrierOptionsList;
