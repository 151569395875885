import { useMutation, useQueryClient } from '@tanstack/react-query';

import API_PATH from '../../constants/api.ts';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys.ts';
import useSessionTimeoutStore from '../../context/session-timeout-context.ts';
import type { UserFormState } from '../../pages/account/UserForm/UserForm.type.ts';
import type { HTTPResponse } from '../../types/api.type.ts';
import type { UserResponse } from '../../types/user.type.ts';
import api from '../api.ts';

function useMutateUser(gid: string) {
  const updateLastUserInteractionTime = useSessionTimeoutStore(s => s.updateLastUserInteractionTime);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: UserFormState) =>
      await api
        .patch<HTTPResponse<UserResponse>>(API_PATH.user.replace(':gid', gid), { body: { data: { user: data } } })
        .then(async (res) => {
          if (res.session.refreshed_at) {
            updateLastUserInteractionTime(res.session.refreshed_at * 1000);
          }
          await queryClient.setQueryData([QUERY_CACHE_KEYS.user, res.data.user.gid], res.data);

          return res.data;
        }),
  });
}

export default useMutateUser;
