import { useEffect, useRef } from 'react';
import analytics from '../utils/analytics.util.ts';
import useSegment from './use-segment.ts';

function useTrackPage(pageKey: string) {
  const { page } = useSegment();
  const pageViewedTracked = useRef(false);

  useEffect(() => {
    if (pageKey && !pageViewedTracked.current) {
      page(analytics.generatePageName(pageKey));
      pageViewedTracked.current = true;
    }
  }, [pageKey, page]);
}

export default useTrackPage;
