import type { FC } from 'react';
import formatUtil from '../../../utils/format.util.ts';
import { containerCSS, descriptionCSS, headerCSS, iconCSS, valueCSS } from './QuoteCoverage.style.ts';
import type { QuoteCoverageProps } from './QuoteCoverage.type.ts';

const QuoteCoverage: FC<QuoteCoverageProps> = ({ customCSS, title, description, value, icon }) => {
  return (
    <div css={[containerCSS, customCSS]}>
      <img css={iconCSS} src={icon} alt="" />
      <div css={headerCSS}>
        <div>{title}</div>
        <div css={valueCSS}>
          {formatUtil.money(value, { prefix: '$' })}
        </div>
      </div>
      <div css={descriptionCSS}>{description}</div>
    </div>
  );
};

export default QuoteCoverage;
