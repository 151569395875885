import type { FC } from 'react';
import { useEffect, useRef } from 'react';
import useSegment from '../../hooks/use-segment.ts';
import type { TrackOnInitProps } from './TrackOnInit.type';

const TrackOnInit: FC<TrackOnInitProps> = ({ eventName, config }) => {
  const { track } = useSegment();
  const renderRef = useRef(false);

  useEffect(() => {
    if (!renderRef.current) {
      renderRef.current = true;
      track(eventName, config);
    }
  }, [track, eventName, config]);

  return null;
};

export default TrackOnInit;
