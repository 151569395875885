import type { FC } from 'react';
import { NavLink } from 'react-router-dom';
import CloseIcon from '../../../assets/svg/close-menu.svg?react';
import MaticLogoIcon from '../../../assets/svg/matic-logo.svg?react';
import AccountIcon from '../../../assets/svg/navigation/nav-account.svg?react';
import ContactsIcon from '../../../assets/svg/navigation/nav-contacts.svg?react';
import InsightsIcon from '../../../assets/svg/navigation/nav-insights.svg?react';
import PoliciesIcon from '../../../assets/svg/navigation/nav-policies.svg?react';
import ROUTES from '../../../constants/routes.ts';
import type { NavigationFullscreenProps } from './NavigationFullscreen.type.ts';
import {
  closeButtonCSS,
  contactLinkCSS,
  contactNavItemCSS,
  containerCSS,
  headerCSS,
  logoCSS,
  navIconCSS,
  navLinkCSS,
  navLinkInnerCSS,
  navigationCSS,
} from './NavigationFullscreen.style.ts';

const NavigationFullscreen: FC<NavigationFullscreenProps> = ({
  customCSS,
  gid,
  userName,
  phoneNumber,
  email,
  onClose,
}) => {
  return (
    <div css={[containerCSS, customCSS]}>
      <div css={headerCSS}>
        <button aria-label="Close modal" css={closeButtonCSS} onClick={onClose} type="button">
          <CloseIcon />
        </button>
        <MaticLogoIcon css={logoCSS} />
      </div>

      <nav css={navigationCSS}>
        <NavLink css={navLinkCSS} to={ROUTES.insights.replace(':gid', gid)}>
          {({ isActive }) => (
            <span css={navLinkInnerCSS(isActive)}>
              <InsightsIcon css={navIconCSS} />
              Insights
            </span>
          )}
        </NavLink>
        <NavLink css={navLinkCSS} to={ROUTES.policies.replace(':gid', gid)}>
          {({ isActive }) => (
            <span css={navLinkInnerCSS(isActive)}>
              <PoliciesIcon css={navIconCSS} />
              Policies
            </span>
          )}
        </NavLink>
        <NavLink css={navLinkCSS} to={ROUTES.account.replace(':gid', gid)}>
          {({ isActive }) => (
            <span css={navLinkInnerCSS(isActive)}>
              <AccountIcon css={navIconCSS} />
              {userName}
            </span>
          )}
        </NavLink>
      </nav>

      <div css={contactNavItemCSS}>
        <ContactsIcon css={navIconCSS} />
        Contact Us
      </div>
      <a css={contactLinkCSS} href={`tel:${phoneNumber}`}>
        {phoneNumber}
      </a>
      <a css={contactLinkCSS} href={`mailto:${email}`}>
        {email}
      </a>
    </div>
  );
};

export default NavigationFullscreen;
