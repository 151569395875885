import type { Carrier } from '../../../types/carrier.ts';

export enum QuoteStatus {
  Unknown = 'unknown',
  Pending = 'pending',
  Ready = 'ready',
  Failed = 'failed',
  Manual = 'manual',
}

export interface QuoteItemProps {
  carrier: Carrier;
  quote?: {
    gid?: string | null;
    premium?: number | string | null;
    status: QuoteStatus;
  };
  isUpdating?: boolean;
  onQuoteUnlock?: () => void;
  onQuoteChoose?: (quoteGid: string) => void;
}
