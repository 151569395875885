import { css } from '@emotion/react';

import THEME from '../../../styles/theme.ts';
import { mq } from '../../../styles/media-queries.ts';

export const formInnerCSS = css`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-right: 0;
  ${mq[0]} {
    margin-right: -16px;
  }
`;

export const validationErrorCSS = css`
  margin-top: 12px;
`;

export const buttonCSS = css`
  margin-top: 4px;
`;

export const disclosuresCSS = css`
  color: ${THEME.color.gray60};
  font-size: 12px;
  line-height: 16px;
  margin-top: 20px;
`;
