import type { Carrier } from './carrier.ts';

export enum PolicyType {
  Home = 'home',
  Auto = 'auto',
}

export enum InsurableInterest {
  RealProperty = 'real_property',
  Vehicle = 'vehicle',
}

export enum CoverageKey {
  Dwelling = 'dwelling',
  OtherStructures = 'other_structures',
  PersonalProperty = 'personal_property',
  LossOfUse = 'loss_of_use',
  PersonalLiability = 'personal_liability',
  MedicalPayments = 'medical_payments',

  Hurricane = 'hurricane',
  WindHail = 'wind_hail',
  WaterBackup = 'water_backup',
  Earthquake = 'earthquake',
  Flood = 'flood',

  BiPerPerson = 'bodily_injury_per_person',
  BiPerAccident = 'bodily_injury_per_accident',
  PropertyDamage = 'property_damage',
  UninsuredMpd = 'uninsured_motorist_property_damage',
  UninsuredMbiPerPerson = 'uninsured_motorist_bodily_injury_per_person',
  UninsuredMbiPerAccident = 'uninsured_motorist_bodily_injury_per_accident',
  UnderinsuredMbiPerPerson = 'underinsured_motorist_bodily_injury_per_person',
  UnderinsuredMbiPerAccident = 'underinsured_motorist_bodily_injury_per_accident',
  Comprehensive = 'comprehensive',
  Collision = 'collision',
  Towing = 'towing',
  RentalPerDay = 'rental_per_day',
  RentalMaxAmount = 'rental_max_amount',
  PersonalInjuryProtection = 'personal_injury_protection',
}

export interface QuoteCoverage {
  key: CoverageKey;
  title: string;
  description: string;
  asset_gid: string;
  limit_value: string | number;
  limit_description: string;
  deductible_value?: string | number | null;
  deductible_description: string | null;
}

export interface QuoteCarrier extends Carrier {
  description: string;
}

export enum PaymentOption {
  Downpaymwent = 'downpayment',
  SubsequentPayment = 'subsequent_payment',
}

export enum QuoteKind {
  Rc1 = 'rc1',
  Rc2 = 'rc2',
  NonIntegrated = 'non_integrated',
}

export interface Quote {
  gid: string;
  created_at: string;
  kind: QuoteKind;
  status: string;
  state: string;
  premium: number;
  bundled_premium: number | null;
  carrier: QuoteCarrier;
  assets_gids: string[] | null;
  message: string | null;
  policy_type: PolicyType;
  requirement: string;
  insurable_interest: InsurableInterest;
  mpd_discount: number | null;
  coverages: QuoteCoverage[] | null;
  coinsureds_gids: string[] | null;
  effective_date: string;
  payment_options: Record<PaymentOption, string | number>[] | null;
}

export interface SuggestedQuoteResponse {
  quote: Quote | null;
  auto_quote?: { // TODO: Refactor this after MVP. Maybe use a group pattern.
    quote: Quote;
  };
}

export enum QuotingStatus {
  Pending = 'pending',
  Finished = 'finished',
}

export interface QuotesResponse {
  quotes: Quote[];
  quotes_request_status: QuotingStatus;
}
