import { type FC, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import useSessionRefresh from '../../api/authentification/use-session-refresh.ts';
import useSignOut from '../../api/authentification/use-sign-out.ts';
import ROUTES from '../../constants/routes.ts';
import useSessionTimeoutStore from '../../context/session-timeout-context.ts';
import Modal from '../../shared/components/Modal/Modal.tsx';
import { ModalSize } from '../../shared/components/Modal/Modal.type.ts';
import TrackOnInit from '../TrackOnInit/TrackOnInit.tsx';
import { SEGMENT } from '../../constants/analytics.ts';
import { DEFAULT_SESSION_TIMEOUT_MIL_SEC, SESSION_TIMEOUT_WAITING_SEC } from './SessionExpiredModal.util.ts';

const SessionExpiredModal: FC = () => {
  const { mutateAsync: sessionRefresh } = useSessionRefresh();
  const { mutateAsync: signOutUser } = useSignOut();
  const lastUserInteractionTime = useSessionTimeoutStore(s => s.lastUserInteractionTime);
  const [secondsLeft, setTimeLeft] = useState<null | number>(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const call = async () => {
      setIsLoading(true);
      navigate(ROUTES.signIn);
    };

    if (secondsLeft === 0 && !isLoading) {
      call();
    }
  }, [secondsLeft, signOutUser, navigate, setIsLoading, isLoading]);

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = Date.now();
      const elapsed = currentTime - lastUserInteractionTime;
      const left = DEFAULT_SESSION_TIMEOUT_MIL_SEC - elapsed;
      const leftSec = Math.trunc(left / 1000);

      if (leftSec <= SESSION_TIMEOUT_WAITING_SEC) {
        setIsLoading(false);
        setTimeLeft(leftSec);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [lastUserInteractionTime]);

  const handleSignOut = async (): Promise<void> => {
    setIsLoading(true);
    await signOutUser({});
    navigate(ROUTES.signIn);
  };

  const handleSessionRefresh = async (): Promise<void> => {
    setIsLoading(true);
    await sessionRefresh();
    setTimeLeft(null);
  };

  if (!secondsLeft) {
    return null;
  }

  return (
    <>
      <Modal
        isOpen
        title="Your session is about to expire"
        blurBackdrop
        size={ModalSize.Small}
        actionButtons={[
          {
            text: 'Log Off',
            variant: 'secondary',
            isLoading,
            onClick: handleSignOut,
          },
          {
            text: 'Stay Logged In',
            isLoading,
            onClick: handleSessionRefresh,
          },
        ]}
      >
        For security purposes, inactive sessions are automatically close.
        Your session will close in {secondsLeft} seconds.
      </Modal>
      <TrackOnInit eventName={SEGMENT.events.sessionExpirationWarningViewed} />
    </>
  );
};

export default SessionExpiredModal;
