import type { FC } from 'react';
import Container from '../../shared/components/Container/Container.tsx';
import Footer from '../../shared/components/Footer/Footer.tsx';
import PortalHeader from '../PortalHeader/PortalHeader.tsx';
import useSettingsQuery from '../../api/settings/use-settings-query.ts';
import useSegment from '../../hooks/use-segment.ts';
import { SEGMENT } from '../../constants/analytics.ts';
import { containerInnerCSS } from './PageContainer.style';
import type { PageContainerProps } from './PageContainer.type';

const PageContainer: FC<PageContainerProps> = ({ customCSS, pageKey, children }) => {
  const { data: settingsData } = useSettingsQuery();
  const { track } = useSegment();

  const onPhoneClicked = (): void => {
    track(SEGMENT.events.phoneConversationInitiated, {
      page: pageKey,
      location: SEGMENT.locations.footer,
    });
  };

  return (
    <>
      <PortalHeader pageKey={pageKey} customCSS={customCSS?.header} />
      <Container customCSS={[containerInnerCSS, customCSS?.innerContainer]}>{children}</Container>
      <Footer
        phone={settingsData?.phone_number}
        inverted
        onSocialLinkClick={onPhoneClicked}
        customCSS={customCSS?.footer}
      />
    </>
  );
};

export default PageContainer;
