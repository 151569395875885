import type { FC } from 'react';

import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';

import useSendMagicLink from '../../api/authentification/use-send-magic-link.ts';
import Illustration from '../../assets/svg/forbidden-sheet.svg?react';
import PageInfoContainer from '../../components/PageInfoContainer/PageInfoContainer.tsx';
import ROUTES from '../../constants/routes.ts';
import Button from '../../shared/components/Button/Button.tsx';

import useTrackPage from '../../hooks/use-track-page.ts';
import { SEGMENT } from '../../constants/analytics.ts';
import useSegment from '../../hooks/use-segment.ts';
import { illustrationCSS } from './index.style.ts';

const LinkExpiredLayout: FC = () => {
  const [searchParams] = useSearchParams();
  const personGid = searchParams.get('gid');
  const targetPage = searchParams.get('target_page');
  const navigate = useNavigate();
  const { mutateAsync: sendMagicLink, isPending: isSendPending } = useSendMagicLink();
  useTrackPage(SEGMENT.pages.linkExpired);

  const { track } = useSegment();

  // TODO: Remove before v2 release
  if (window.location.host.includes('my.matic.com')) {
    return <Navigate to={ROUTES.problem} />;
  }

  const handleResendLink = async (): Promise<void> => {
    track(SEGMENT.events.authenticateLinkSent, {
      page: SEGMENT.pages.linkExpired,
    });

    if (!personGid) {
      // TODO: Throw Sentry error
      return;
    }
    const params: { gid: string; targetPage?: string } = { gid: personGid };
    if (targetPage) {
      params.targetPage = targetPage;
    }
    const res = await sendMagicLink(params);
    navigate(`${ROUTES.checkEmail}?gid=${personGid}&email=${res.user.email}&target_page=${targetPage}`);
  };

  return (
    <PageInfoContainer
      heading="Your link has expired"
      subHeading="That link is expired or has already been used, but we can send you a new link."
    >
      <Illustration css={illustrationCSS} />
      <Button fullWidth size="large" isLoading={isSendPending} onClick={handleResendLink}>
        Resend Link
      </Button>
    </PageInfoContainer>
  );
};

export default LinkExpiredLayout;
