import { css } from '@emotion/react';

import { mq } from '../../styles/media-queries';

import THEME from '../../styles/theme.ts';

export const userWithChatIconCSS = css`
  margin-right: 8px;
  width: 16px;
  height: 16px;
`;

export const toggletipButtonCSS = css`
  display: flex;
  align-items: center;
  font-weight: 700;
  border: 2px solid ${THEME.color.azure50};
  border-radius: 5px;
  padding: 4px 10px;
  color: ${THEME.color.azure50};
  background-color: ${THEME.color.white};
  font-size: 14px;
  line-height: 20px;
  white-space: nowrap;

  ${mq[0]} {
    padding: 4px 12px;
  }
`;

export const actionButtonCSS = css`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
`;

export const scheduleCallCSS = css`
  margin-top: 16px;
  span {
    ${actionButtonCSS};
  }
  width: 100%;
`;

export const buttonIconCSS = css`
  margin-right: 9px;
  width: 19px;
  height: 19px;
`;

export const headerTextCSS = css`
  margin-bottom: 8px;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
`;

export const subHeaderTextCSS = css`
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
`;

export const textWrapperCSS = css`
  margin-bottom: 24px;
  color: ${THEME.color.black};
  font-family: ${THEME.typography.fontFamily};
`;

export const toggletipContentCSS = css`
  padding: 20px;
`;
