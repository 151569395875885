import { type FC, Fragment } from 'react';

import usePersonAssetsQuery from '../../../api/proposal/use-person-assets-query';
import usePublicRecentPolicyQuery from '../../../api/proposal/use-public-recent-policy-query';
import Comment from '../../../components/Comment/Comment';
import ProposalCard from '../../../components/ProposalCard/ProposalCard';
import { CoverageKey } from '../../../types/quote.type';
import { moneyFormatter } from '../../../utils/formatters';
import {
  additionalCoveragesExcludedCSS,
  additionalCoveragesExcludedTextCSS,
  additionalCoveragesIncludedCSS,
  addressLineCSS,
  boldTextCSS,
  carrierLineCSS,
  containerCSS,
  coverageLineCSS,
  dividerLineCSS,
  headerCSS,
  headerLineCSS,
} from './ProposalHomeQuoteInfo.style';
import type { ProposalHomeQuoteInfoProps } from './ProposalHomeQuoteInfo.type';
import {
  CONTENT_COMMENTS,
  getAdditionalCoverageTitle,
  getDeductibleByCoverageKey,
  getDeductibleComment,
  getDwellingComment,
  getExcludedAdditionalCoverages,
  getIncludedAdditionalCoverages,
  getLimitByCoverageKey,
} from './ProposalHomeQuoteInfo.util';

const ProposalHomeQuoteInfo: FC<ProposalHomeQuoteInfoProps> = ({ quote, personGid }) => {
  const { data: assets } = usePersonAssetsQuery({ personGid, assetGids: quote.assets_gids });
  const { data: recentPolicy } = usePublicRecentPolicyQuery({
    personGid,
    assetGid: quote.assets_gids?.[0],
    policyTypes: [quote.policy_type],
  });

  const home = assets?.homes[0];
  const coverages = quote.coverages;

  const deductible = getDeductibleByCoverageKey(coverages, CoverageKey.Dwelling);
  const dwelling = getLimitByCoverageKey(coverages, CoverageKey.Dwelling);
  const otherStructures = getLimitByCoverageKey(coverages, CoverageKey.OtherStructures);
  const personalProperty = getLimitByCoverageKey(coverages, CoverageKey.PersonalProperty);
  const lossOfUse = getLimitByCoverageKey(coverages, CoverageKey.LossOfUse);
  const personalLiability = getLimitByCoverageKey(coverages, CoverageKey.PersonalLiability);
  const medicalPayments = getLimitByCoverageKey(coverages, CoverageKey.MedicalPayments);
  const additionalCoveragesIncluded = getIncludedAdditionalCoverages(coverages);
  const additionalCoveragesExcluded = getExcludedAdditionalCoverages(coverages);
  const deductibleComment = getDeductibleComment(quote, recentPolicy);
  const dwellingComment = getDwellingComment(quote, recentPolicy);

  return (
    <ProposalCard isTextCard>
      <div css={containerCSS}>
        <p css={headerCSS}>Coverage and pricing summary</p>
        <p css={addressLineCSS}>{home?.address.full}</p>
        <div css={carrierLineCSS}>
          <p css={boldTextCSS}>Carrier</p>
          <img src={quote.carrier.logo_url} alt={quote.carrier.name} width="120" />
        </div>
        <div css={headerLineCSS}>
          <p css={boldTextCSS}>Matic recommended coverages</p>
          <p css={boldTextCSS}>Limit</p>
        </div>
        {deductible && (
          <>
            <div css={coverageLineCSS}>
              <p>Deductible</p>
              <p>{deductible}</p>
            </div>
            <Comment content={deductibleComment} />
          </>
        )}
        {dwelling && (
          <>
            <div css={coverageLineCSS}>
              <p>Dwelling</p>
              <p>{dwelling}</p>
            </div>
            {dwellingComment && <Comment content={dwellingComment} />}
          </>
        )}

        {otherStructures && (
          <div css={coverageLineCSS}>
            <p>Other structures</p>
            <p>{otherStructures}</p>
          </div>
        )}

        {personalProperty && (
          <div css={coverageLineCSS}>
            <p>Personal property</p>
            <p>{personalProperty}</p>
          </div>
        )}
        {lossOfUse && (
          <div css={coverageLineCSS}>
            <p>Loss of use</p>
            <p>{lossOfUse}</p>
          </div>
        )}
        {personalLiability && (
          <div css={coverageLineCSS}>
            <p>Personal liability</p>
            <p>{personalLiability}</p>
          </div>
        )}
        {medicalPayments && (
          <div css={coverageLineCSS}>
            <p>Medical payment (to others)</p>
            <p>{medicalPayments}</p>
          </div>
        )}
        {additionalCoveragesIncluded && additionalCoveragesIncluded?.length > 0 && (
          <>
            <p css={additionalCoveragesIncludedCSS}>Additional coverages included</p>
            {additionalCoveragesIncluded.map(coverage => (
              <Fragment key={coverage.key}>
                <div css={coverageLineCSS}>
                  <p>{getAdditionalCoverageTitle(coverage.key)}</p>
                  <p>{coverage.limit_description}</p>
                </div>
                {coverage.key === CoverageKey.WaterBackup && <Comment content={CONTENT_COMMENTS.waterBackup} />}
              </Fragment>
            ))}
          </>
        )}
        {additionalCoveragesExcluded && additionalCoveragesExcluded?.length > 0 && (
          <>
            <div css={additionalCoveragesExcludedCSS}>
              <p css={boldTextCSS}>Excluded coverages</p>
              <p css={additionalCoveragesExcludedTextCSS}>Request agent to include as needed</p>
            </div>
            {additionalCoveragesExcluded.map(coverage => (
              <div css={coverageLineCSS} key={coverage.key}>
                <p>{getAdditionalCoverageTitle(coverage.key)}</p>
                <p>{coverage.limit_description}</p>
              </div>
            ))}
          </>
        )}

        <div css={dividerLineCSS} />
        <div css={coverageLineCSS}>
          <p css={headerCSS}>Yearly premium</p>
          <p css={headerCSS}>{moneyFormatter(quote.premium)}</p>
        </div>
      </div>
    </ProposalCard>
  );
};

export default ProposalHomeQuoteInfo;
