import type { FC } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import PageContainer from '../../components/PageContainer/PageContainer.tsx';
import SectionHeading from '../../components/SectionHeading/SectionHeading.tsx';
import {
  columnsWrapperCSS,
  customSectionHeadingCSS,
  leftColumnCSS,
  pageHeadingCSS,
  pageSubheadingCSS,
  rightColumnCSS,
} from '../../styles/layout.style.ts';
import SuggestedPolicy from '../../components/SuggestedPolicy/SuggestedPolicy.tsx';
import { ScoreType } from '../../types/score.type.ts';
import FactorsList from '../../components/FactorsList/FactorsList.tsx';
import ROUTES from '../../constants/routes.ts';
import type { DefaultRouteParams } from '../../types/route-params.type.ts';
import MaticContent from '../../components/MaticContent/MaticContent.tsx';
import useResponsive from '../../hooks/use-responsive.ts';
import { SEGMENT } from '../../constants/analytics.ts';
import useTrackPage from '../../hooks/use-track-page.ts';
import ReplacementCostHistory from './ReplacementCostHistory/ReplacementCostHistory.tsx';

const ReplacementCostLayout: FC = () => {
  const { gid } = useParams() as DefaultRouteParams;
  const navigate = useNavigate();
  const { isMobile } = useResponsive();

  useTrackPage(SEGMENT.pages.replacementCost);

  const handleShowMoreQuotesClick = () => {
    navigate(ROUTES.carrierOptions.replace(':gid', gid), { state: { scrollToList: true } });
  };

  return (
    <PageContainer pageKey={SEGMENT.pages.replacementCost}>
      <h1 css={pageHeadingCSS}>
        Replacement Cost
      </h1>
      <p css={pageSubheadingCSS}>Do you have the right coverage for your home?</p>

      <div css={columnsWrapperCSS}>
        <div css={leftColumnCSS}>
          <ReplacementCostHistory />

          <SectionHeading
            title="Replacement cost factors"
            subtitle="Learn about what impacts your replacement cost and what you can do to protect your home"
          />
          <FactorsList
            page={SEGMENT.pages.replacementCost}
            scoreType={ScoreType.Replacement}
            noFactorsButtonLabel="Unlock Your Replacement Cost Factors"
          />

          {!isMobile && (
            <>
              <SectionHeading title="Home Insurance Resources" />
              <MaticContent page={SEGMENT.pages.replacementCost} />
            </>
          )}
        </div>

        <div css={rightColumnCSS}>
          <SectionHeading title="Suggested Policy" customCSS={customSectionHeadingCSS} />
          <SuggestedPolicy page={SEGMENT.pages.replacementCost} onShowMoreQuotesClick={handleShowMoreQuotesClick} />

          {isMobile && (
            <div>
              <SectionHeading title="Home Insurance Resources" />
              <MaticContent page={SEGMENT.pages.replacementCost} />
            </div>
          )}
        </div>
      </div>
    </PageContainer>
  );
};

export default ReplacementCostLayout;
