import { type FC, useState } from 'react';

import ProposalCard from '../../../components/ProposalCard/ProposalCard';
import Button from '../../../shared/components/Button/Button';
import THEME from '../../../styles/theme';
import AgentContactInfoModal from '../AgenContactInfoModal/AgentContactInfoModal';
import { bodyCSS, buttonCSS, containerCSS, headerCSS, textBodyCSS } from './ProposalAd.style';
import type { ProposalAdProps } from './ProposalAd.type';

const ProposalAd: FC<ProposalAdProps> = ({ agentContactInfo, isProposalExpired }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  return (
    <ProposalCard backgroundColor={THEME.color.warningYellow94}>
      <div css={containerCSS}>
        <div css={bodyCSS}>
          <div css={textBodyCSS}>
            <p css={headerCSS}>Act now to secure your rate</p>
            <p>Rates can change quickly, so don’t wait to accept your quote. Buy now to lock in your current offer.</p>
          </div>
        </div>
        <Button customCSS={buttonCSS} onClick={() => setModalOpen(true)}>
          Contact Your Advisor
        </Button>
      </div>
      {isModalOpen && (
        <AgentContactInfoModal
          agentContactInfo={agentContactInfo}
          setModalOpen={setModalOpen}
          isProposalExpired={isProposalExpired}
        />
      )}
    </ProposalCard>
  );
};

export default ProposalAd;
