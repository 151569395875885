export interface InitProps {
  gid?: string;
  url?: string | null;
  isPopup?: boolean;
  prefillData?: {
    firstName?: string;
    lastName?: string;
    email?: string;
  };
  onEventScheduled?: () => void;
}

export interface CalendlyData {
  step: number;
  isLoaded: boolean;
  calendlyInit: () => void;
}

export enum CALENDLY_STEP {
  init = 0,
  viewed = 1,
  timeSelected = 2,
  eventScheduled = 3,
}
