import { type UseBaseMutationResult, useMutation, useQueryClient } from '@tanstack/react-query';

import API_PATH from '../../constants/api.ts';
import type { HTTPResponse } from '../../types/api.type.ts';
import type { UserResponse } from '../../types/user.type.ts';
import api from '../api.ts';

function useSignOut(): UseBaseMutationResult<UserResponse, HTTPResponse, Record<never, never>> {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () =>
      await api.delete<HTTPResponse<UserResponse>>(API_PATH.sessionCurrent).then(async (res) => {
        return res.data;
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries();
    },
  });
}
export default useSignOut;
