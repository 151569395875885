import type { FC } from 'react';
import PageContainer from '../../components/PageContainer/PageContainer';
import { SEGMENT } from '../../constants/analytics.ts';
import useTrackPage from '../../hooks/use-track-page.ts';
import ScheduleCallPage from './ScheduleCallPage/ScheduleCallPage';
import { customPageContainerCSS } from './index.style';

const ScheduleCallLayout: FC = () => {
  useTrackPage(SEGMENT.pages.scheduleCall);

  return (
    <PageContainer pageKey={SEGMENT.pages.scheduleCall} customCSS={{ innerContainer: customPageContainerCSS }}>
      <ScheduleCallPage />
    </PageContainer>
  );
};

export default ScheduleCallLayout;
