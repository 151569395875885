import type { User } from '../../../types/user.type.ts';
import type { UserFormState } from './UserForm.type.ts';

export function userDataHasChanges(
  userData: UserFormState,
  initialUserData: User | Record<string, unknown> = {},
): boolean {
  // @ts-expect-error types are not matching
  return Object.keys(userData).some(key => userData[key] !== initialUserData[key]);
}
