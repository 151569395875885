import type { Quote } from '../types/quote.type.ts';
import { CoverageKey } from '../types/quote.type.ts';

export function getQuoteDeductible(quote: Quote): string | number {
  const dwellingCoverage = quote.coverages?.find(coverage => coverage.key === CoverageKey.Dwelling);

  if (!dwellingCoverage || !dwellingCoverage.deductible_value) {
    return '';
  }

  return dwellingCoverage.deductible_value;
}

export function getQuoteDwelling(quote: Quote): string | number {
  const dwellingCoverage = quote.coverages?.find(coverage => coverage.key === CoverageKey.Dwelling);

  if (!dwellingCoverage) {
    return '';
  }

  return dwellingCoverage.limit_value;
}

export function hasIntegratedQuote(quotes: Quote[]): boolean {
  return quotes.some(quote => quote.kind !== 'non_integrated');
}
