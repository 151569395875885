import { css } from '@emotion/react';

import THEME from '../../../styles/theme';

export const headerCSS = css`
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
`;

export const containerCSS = css`
  display: flex;
  gap: 12px;
  flex-direction: column;
`;

export const autoDescriptionCSS = css`
  color: ${THEME.color.gray60};
`;

export const carrierLineCSS = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 24px;
`;

export const boldTextCSS = css`
  font-weight: bold;
`;

export const headerLineCSS = css`
  display: flex;
  justify-content: space-between;
  padding-top: 12px;
`;

export const coverageLineCSS = css`
  display: flex;
  justify-content: space-between;
`;

export const dividerLineCSS = css`
  height: 1px;
  width: 100%;
  background-color: ${THEME.color.gray10};
  margin: 24px 0 24px 0;
`;

export const autoTitleCSS = css`
  font-weight: bold;
  color: ${THEME.color.violet};
`;
