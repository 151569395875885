export const CHART_DATA_SET = {
  status: 'ready',
  history: {
    2022: {
      replacement_cost: '1500',
      replacement_cost_min: '1470',
      replacement_cost_max: '1617',
    },
    2023: {
      replacement_cost: '1570',
      replacement_cost_min: '1500',
      replacement_cost_max: '1650',
    },
    2024: {
      replacement_cost: '1400',
      replacement_cost_min: '1570',
      replacement_cost_max: '1727',
    },
    2025: {
      replacement_cost: '1600',
      replacement_cost_min: '1590',
      replacement_cost_max: '1749',
    },
  },
};
