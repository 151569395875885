import { useQuery } from '@tanstack/react-query';

import API_PATH from '../../constants/api';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys';
import useSessionTimeoutStore from '../../context/session-timeout-context.ts';
import type { HTTPResponse } from '../../types/api.type';
import type { UserResponse } from '../../types/user.type.ts';
import api from '../api';

function useUserQuery(gid?: string | null, throwOnError = true) {
  const updateLastUserInteractionTime = useSessionTimeoutStore(s => s.updateLastUserInteractionTime);

  return useQuery({
    queryKey: [QUERY_CACHE_KEYS.user, gid],
    enabled: !!gid,
    throwOnError,
    refetchOnWindowFocus: true,

    queryFn: async () =>
      await api.get<HTTPResponse<UserResponse>>(API_PATH.user.replace(':gid', gid as string)).then((res) => {
        if (res.session.refreshed_at) {
          updateLastUserInteractionTime(res.session.refreshed_at * 1000);
        }
        return res.data;
      }),
    // NOTE: This is a dev-only option to refetch the data every 5 minutes for session token refreshing
    ...(import.meta.env.DEV && {
      refetchInterval: 1000 * 60 * 5, // 5 minutes
      refetchIntervalInBackground: true,
    }),
  });
}
export default useUserQuery;
