import { useQuery } from '@tanstack/react-query';
import API_PATH from '../../constants/api.ts';
import useSessionTimeoutStore from '../../context/session-timeout-context.ts';
import type { HTTPResponse } from '../../types/api.type.ts';
import api from '../api.ts';
import type { HistoryResponse, ReplacementCostHistoryItem } from '../../types/history.type.ts';
import { HistoryDataStatuses } from '../../types/history.type.ts';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys.ts';

export default function useReplacementCostHistoryQuery(gid: string) {
  const updateLastUserInteractionTime = useSessionTimeoutStore(s => s.updateLastUserInteractionTime);

  return useQuery({
    queryKey: [QUERY_CACHE_KEYS.replacementCostHistory, gid],
    refetchInterval: (query) => {
      return query.state.data?.status === HistoryDataStatuses.Pending ? 3000 : false;
    },
    queryFn: async () =>
      await api
        .get <HTTPResponse<HistoryResponse<ReplacementCostHistoryItem>>>(
          API_PATH.replacementCostPremium().replace(':gid', gid),
        )
        .then((res) => {
          if (res.session.refreshed_at) {
            updateLastUserInteractionTime(res.session.refreshed_at * 1000);
          }
          return res.data;
        }),
  });
}
