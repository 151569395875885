import type { QueryClientConfig } from '@tanstack/react-query';
import type { AxiosError, AxiosResponse } from 'axios';

import type { HTTPResponse } from '../types/api.type.ts';

function retry(failureCount: number, error: unknown): boolean {
  const response = (error as AxiosError).response as AxiosResponse<HTTPResponse>;

  return failureCount < 3 && (!response || response.status >= 500);
}

export const QUERY_CLIENT_CONFIG: QueryClientConfig = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry,
    },
  },
};
