import { useQuery } from '@tanstack/react-query';
import API_PATH from '../../constants/api';
import useSessionTimeoutStore from '../../context/session-timeout-context.ts';
import type { HTTPResponse } from '../../types/api.type';
import api from '../api';
import { HistoryDataStatuses, type HistoryResponse } from '../../types/history.type.ts';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys.ts';

export default function usePremiumHistoryQuery(gid: string) {
  const updateLastUserInteractionTime = useSessionTimeoutStore(s => s.updateLastUserInteractionTime);

  return useQuery({
    queryKey: [QUERY_CACHE_KEYS.premiumHistory, gid],
    refetchInterval: (query) => {
      return query.state.data?.status === HistoryDataStatuses.Pending ? 3000 : false;
    },
    queryFn: async () =>
      await api
        .get<HTTPResponse<HistoryResponse>>(API_PATH.historyPremium().replace(':gid', gid))
        .then((res) => {
          if (res.session.refreshed_at) {
            updateLastUserInteractionTime(res.session.refreshed_at * 1000);
          }
          return res.data;
        }),
  });
}
