import { type SerializedStyles, css } from '@emotion/react';

import THEME from '../../styles/theme.ts';
import { FactorLevel } from '../../types/score.type.ts';

export const containerCSS = css`
  width: 100%;
`;

export const topWrapperCSS = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

export const labelWrapperCSS = css`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
`;

export function valueCSS(highlightValue?: boolean, riskLevel?: FactorLevel | null): SerializedStyles {
  return css`
    font-size: 16px;
    font-weight: 700;
    color: ${highlightValue && riskLevel ? getLevelColor(riskLevel) : THEME.color.black};
`;
}

export const valueButtonCSS = css`
  text-align: right;
`;

export const lockIconCSS = css`
  display: block;
  width: 16px;
  height: 16px;
`;

export const levelWrapperCSS = css`
  padding-left: 14px;
  position: relative;
`;

function getLevelColor(level?: FactorLevel | null): string {
  switch (level) {
    case FactorLevel.Low:
      return THEME.color.positiveGreen27;
    case FactorLevel.Moderate:
      return THEME.color.carrot;
    case FactorLevel.High:
      return THEME.color.errorRed;
    default:
      return THEME.color.gray30;
  }
}

export function levelMarkerCSS(riskLevel?: FactorLevel | null): SerializedStyles {
  return css`
  position: absolute;
  left: 0;
  top: calc(50% - 4px);
  display: block;
  width: 8px;
  height: 8px;
  background-color: ${getLevelColor(riskLevel)};
  border-radius: 50%;
`;
}

export const levelCSS = css`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
`;
