import type { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useUserQuery from '../../api/user/use-user-query.ts';
import CalendarIcon from '../../assets/svg/calendar-icon.svg?react';
import PhoneIcon from '../../assets/svg/phone.svg?react';
import UserWithChat from '../../assets/svg/user-with-chat.svg?react';
import { SEGMENT } from '../../constants/analytics';
import ROUTES from '../../constants/routes';
import useResponsive from '../../hooks/use-responsive';
import useSegment from '../../hooks/use-segment';
import Anchor from '../../shared/components/Anchor/Anchor';
import Button from '../../shared/components/Button/Button';
import { buttonCSS } from '../../shared/components/Button/Button.style';
import Toggletip from '../../shared/components/Toggletip/Toggletip';
import type { DefaultRouteParams } from '../../types/route-params.type.ts';
import Format from '../../utils/format.util';
import useSettingsQuery from '../../api/settings/use-settings-query.ts';
import {
  actionButtonCSS,
  buttonIconCSS,
  headerTextCSS,
  scheduleCallCSS,
  subHeaderTextCSS,
  textWrapperCSS,
  toggletipButtonCSS,
  toggletipContentCSS,
  userWithChatIconCSS,
} from './LiveHelpButton.style';
import type { LiveHelpButtonProps } from './LiveHelpButton.type.ts';

const LiveHelpButton: FC<LiveHelpButtonProps> = ({ pageKey }) => {
  const { gid } = useParams() as DefaultRouteParams;
  const { isMobile } = useResponsive();
  const { data: settingsData } = useSettingsQuery();
  const { data: userData } = useUserQuery(gid);
  const phoneNumber = settingsData?.phone_number || '';

  const userName = userData?.user.first_name;
  const { track } = useSegment();
  const navigate = useNavigate();

  const onLiveHelpClick = (): void => {
    track(SEGMENT.events.liveHelpClicked, { page: pageKey });
  };

  const onPhoneClicked = (): void => {
    track(SEGMENT.events.phoneConversationInitiated, {
      page: pageKey,
      location: SEGMENT.locations.header,
    });
  };

  const onScheduleCall = (): void => {
    track(SEGMENT.events.conversationSchedulingInitiated, {
      page: pageKey,
      location: SEGMENT.locations.header,
    });
    if (gid) {
      navigate(ROUTES.scheduleCall.replace(':gid', gid));
    }
  };

  return (
    <Toggletip
      content={(
        <div css={toggletipContentCSS}>
          <div css={textWrapperCSS}>
            <div css={headerTextCSS}>
              Hi {userName},
            </div>
            <div css={subHeaderTextCSS}>Here’s how we can help:</div>
          </div>
          <div>
            <Anchor css={[buttonCSS('middle'), actionButtonCSS]} href={`tel:${phoneNumber}`} onClick={onPhoneClicked}>
              <PhoneIcon css={buttonIconCSS} />
              Call: {Format.phone(phoneNumber)}
            </Anchor>
            <Button css={scheduleCallCSS} variant="secondary" onClick={onScheduleCall}>
              <CalendarIcon css={buttonIconCSS} />
              Schedule A Consultation
            </Button>
          </div>
        </div>
      )}
      customCSS={{ buttonCSS: toggletipButtonCSS }}
      onClick={onLiveHelpClick}
    >
      {!isMobile && <UserWithChat data-testid="chat-icon" css={userWithChatIconCSS} />}
      Live Help
    </Toggletip>
  );
};

export default LiveHelpButton;
