import type { FC } from 'react';

import { useNavigate } from 'react-router-dom';

import useSignOut from '../../api/authentification/use-sign-out.ts';
import PageContainer from '../../components/PageContainer/PageContainer.tsx';
import ROUTES from '../../constants/routes.ts';
import Button from '../../shared/components/Button/Button.tsx';
import { pageHeadingCSS } from '../../styles/layout.style.ts';
import { SEGMENT } from '../../constants/analytics.ts';
import useTrackPage from '../../hooks/use-track-page.ts';
import UserForm from './UserForm/UserForm.tsx';

import { sectionCSS, sectionHeadingCSS } from './index.style.ts';

const AccountLayout: FC = () => {
  const { mutateAsync: signOutUser, isPending } = useSignOut();
  const navigate = useNavigate();

  useTrackPage(SEGMENT.pages.account);

  const handleSignOut = async () => {
    await signOutUser({});
    navigate(ROUTES.signIn);
  };

  return (
    <PageContainer pageKey={SEGMENT.pages.account}>
      <h1 css={pageHeadingCSS}>My Account</h1>

      <div css={sectionCSS}>
        <h2 css={sectionHeadingCSS}>Manage Profile</h2>
        <UserForm />
      </div>

      <div css={sectionCSS}>
        <h2 css={sectionHeadingCSS}>Log Out</h2>
        <Button variant="secondary" isLoading={isPending} onClick={handleSignOut}>
          Log out
        </Button>
      </div>
    </PageContainer>
  );
};

export default AccountLayout;
