import { css } from '@emotion/react';

import { mq } from '../../../styles/media-queries';

export const headerCSS = css`
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
`;

export const containerCSS = css`
  display: flex;
  padding: 12px;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  ${mq[0]} {
    padding: 0;
    align-items: normal;
  }
`;

export const bodyCSS = css`
  display: flex;
  justify-content: space-between;
  gap: 12px;
  flex-direction: column;
  align-items: center;
  ${mq[0]} {
    flex-direction: row;
  }
`;

export const textBodyCSS = css`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const buttonCSS = css`
  width: 190px;
`;
