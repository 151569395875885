import type { FC } from 'react';
import { useParams } from 'react-router-dom';
import InfoRequestTooltip from '../../../components/InfoRequestTooltip/InfoRequestTooltip.tsx';
import useUserQuery from '../../../api/user/use-user-query.ts';
import type { DefaultRouteParams } from '../../../types/route-params.type.ts';
import formatUtil from '../../../utils/format.util.ts';
import PremiumGraph from '../PremiumGraph/PremiumGraph.tsx';
import { CHART_DATA_SET } from '../PremiumGraph/PremiumGraph.utils.ts';
import usePremiumHistoryQuery from '../../../api/history/use-premium-history-query.ts';
import { HistoryDataStatuses } from '../../../types/history.type.ts';
import { addressCSS, chartCSS, hintCSS, notificationCSS, titleCSS } from '../../../styles/chart.style.ts';
import { transformPremiumHistoryData } from '../../../utils/chart.util.ts';
import { SEGMENT } from '../../../constants/analytics.ts';
import type { PremiumHistoryProps } from './PremiumHistory.type.ts';

const PremiumHistory: FC<PremiumHistoryProps> = ({ customCSS }) => {
  const { gid } = useParams() as DefaultRouteParams;
  const { data } = usePremiumHistoryQuery(gid);
  const { data: userData } = useUserQuery(gid);

  const address = userData?.user.address ? formatUtil.address(userData.user.address) : '';

  const getContent = () => {
    switch (data?.status) {
      case HistoryDataStatuses.Ready:
        return (
          <div css={chartCSS(false)}>
            <PremiumGraph data={transformPremiumHistoryData(data?.history)} hidePreview={false} />
            <div css={hintCSS}>Hint: Hover over graph to see data per year.</div>
          </div>
        );
      case HistoryDataStatuses.Pending:
        return (
          <div css={chartCSS()}>
            <PremiumGraph data={transformPremiumHistoryData(CHART_DATA_SET.history)} />
            <div css={notificationCSS}>
              <h3>Almost There!</h3>
              <p>We are still fetching quotes for your home. We will update you when the process is complete.</p>
            </div>
          </div>
        );
      case HistoryDataStatuses.Empty:
        return (
          <div css={chartCSS()}>
            <PremiumGraph data={transformPremiumHistoryData(CHART_DATA_SET.history)} />
            <InfoRequestTooltip page={SEGMENT.pages.rateReview} />
          </div>
        );
    }
  };

  return (
    <div css={customCSS}>
      <div css={titleCSS}>Your Premium  vs Matic’s best quote</div>
      <p css={addressCSS}>🏠 {address}</p>
      {getContent()}
    </div>
  );
};

export default PremiumHistory;
