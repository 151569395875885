import type { FC } from 'react';

import MaticLogo from '../../assets/svg/matic-logo.svg?react';
import Container from '../../shared/components/Container/Container.tsx';
import { containerInnerCSS, headingCSS, logoCSS, subHeadingCSS } from './PageInfoContainer.style';
import type { PageInfoContainerProps } from './PageInfoContainer.type';

const PageInfoContainer: FC<PageInfoContainerProps> = ({ customCSS, heading, subHeading, children }) => {
  return (
    <div css={customCSS}>
      <header>
        <MaticLogo css={logoCSS} />
      </header>
      <Container customCSS={containerInnerCSS} small>
        <h1 css={headingCSS}>{heading}</h1>
        <p css={subHeadingCSS}>{subHeading}</p>
        <div>{children}</div>
      </Container>
    </div>
  );
};

export default PageInfoContainer;
