export const PAGES = {
  QUOTES: 'Quotes Page',
  CONFIRMATION: 'Confirmation page',
  RATE_REVIEW_FOLLOW_UP: 'Rate Review Follow Up Page',
  ERROR: 'Error Page',
};

export const PAGE_KEYS = {
  QUOTES: 'quotes',
  CONFIRMATION: 'confirmation',
  ERROR: 'error',
};

export const FLOW_STARTED = 'Flow Started';
export const ONLINE_BIND_OFFERED = 'Online Bind Offered';
export const QUOTE_REVIEWED = 'Quote Reviewed';
export const QUOTE_SELECTED = 'Quote Selected';
export const SHOW_MORE_QUOTES_CLICKED = 'Show More Quotes Clicked';
export const PHONE_CONVERSATION_INITIATED = 'Phone Conversation Initiated';
export const CONVERSATION_SCHEDULING_INITIATED = 'Conversation Scheduling Initiated';
export const CONVERSATION_SCHEDULED = 'Conversation Scheduled';
export const FAQ_CLICKED = 'FAQ Clicked';

// Segment events v2
export const SEGMENT = {
  events: {
    phoneConversationInitiated: 'Phone Conversation Initiated',
    conversationSchedulingInitiated: 'Conversation Scheduling Initiated',
    scheduleCallTimeSelected: 'Schedule A Call Time Selected',
    conversationSchedulingFinished: 'Conversation Scheduling Finished',
    liveHelpClicked: 'Live Help Clicked',
    suggestedPolicyViewed: 'Suggested Policy Viewed',
    quoteSelected: 'Quote Selected',
    digitalProfileNavigated: 'Digital Profile Navigated',
    accountVerified: 'Account Verified',
    unlockPortalDataRequested: 'Unlock Portal Data Requested',
    blogPostOpened: 'Blog Post Opened',
    accountProfileUpdated: 'Account Profile Updated',
    insightsNavigationClicked: 'Insights Navigation Clicked',
    addNewPolicyClicked: 'Add New Policy Clicked',
    authenticateLinkSent: 'Authenticate Link Sent',
    sessionExpirationWarningViewed: 'Session Expiration Warning Viewed',
  },
  locations: {
    header: 'header',
    footer: 'footer',
    maticTeamBlock: 'matic_team_block',
    scoreCards: 'score_cards',
    rateReviewInsightCard: 'rate_review_insight_card',
    coverageInsightCard: 'coverage_insight_card',
    homeQuoteInsightCard: 'home_quote_insight_card',
    factorsList: 'factors_list',
    suggestedPolicy: 'suggested_policy',
    carrierOptionsList: 'carrier_options_list',
    chart: 'chart',
  },
  pages: {
    insights: 'insights',
    policies: 'policies',
    rateReview: 'rate_review',
    replacementCost: 'replacement_cost',
    carrierOptions: 'carrier_options',
    snapshotDetails: 'snapshot_details',
    digitalProfileIntro: 'digital_profile_intro',
    account: 'account',
    signIn: 'sign_in',
    linkExpired: 'link_expired',
    checkEmail: 'check_email',
    verify: 'verify',
    error: 'error',
    scheduleCall: 'schedule_call',
  },
};
