import dwellingIcon from '../assets/svg/coverages/dwelling.svg';
import lossJfUseIcon from '../assets/svg/coverages/loss-of-use.svg';
import medicalPaymentsIcon from '../assets/svg/coverages/medical-payments.svg';
import otherStructuresIcon from '../assets/svg/coverages/other-structures.svg';
import personalLiabilityIcon from '../assets/svg/coverages/personal-liability.svg';
import personalPropertyIcon from '../assets/svg/coverages/personal-property.svg';
import { CoverageKey } from '../types/quote.type.ts';

// TODO: Consider moving this to a suggested quote API response
// TODO: Check other coverages that we can have
export const COVERAGES: Record<
  string,
  {
    title: string;
    description: string;
    icon: string;
  }
> = {
  [CoverageKey.Dwelling]: {
    title: 'Dwelling Coverage',
    description:
      'If your house is damaged by a covered event, which could include a fire, fallen tree, '
      + 'lightning, or another hazard, this coverage can help you pay to repair or rebuild your home.',
    icon: dwellingIcon,
  },
  [CoverageKey.OtherStructures]: {
    title: 'Other Structures',
    description:
      'If damages occur to a fence, shed, or other structure that isn\'t attached to your house, '
      + 'this coverage can help pay for the cost to repair or rebuild it.',
    icon: otherStructuresIcon,
  },
  [CoverageKey.PersonalProperty]: {
    title: 'Personal Property',
    description:
      'If your furniture, clothing, or other belongings are stolen or damaged during '
      + 'a covered incident, this coverage can help you pay to repair or replace them.',
    icon: personalPropertyIcon,
  },
  [CoverageKey.LossOfUse]: {
    title: 'Loss of Use',
    description:
      'If you\'re unable to live in your home after a claim, this coverage can '
      + 'help you pay for a hotel or rental while your home is repaired or rebuilt.',
    icon: lossJfUseIcon,
  },
  [CoverageKey.PersonalLiability]: {
    title: 'Personal Liability',
    description:
      'If someone sues you because they injure themselves or because their property gets damaged at your '
      + 'home, this coverage can help you pay for expenses like legal fees, medical bills, and replacement items.',
    icon: personalLiabilityIcon,
  },
  [CoverageKey.MedicalPayments]: {
    title: 'Medical Payments',
    description:
      'If another person gets a minor injury on your property, '
      + 'this coverage can help pay for their medical expenses regardless of who is at fault.',
    icon: medicalPaymentsIcon,
  },
  [CoverageKey.WindHail]: {
    title: 'Wind and Hail',
    description:
      'If your home is damaged by wind or hail, this coverage can help you pay for repairs. Each state and '
      + 'carrier has different policies for wind and hail damage, so ask your insurance advisor for more information.',
    icon: '', // TODO need add icon and update filter when we wanna use this coverage
  },
};
