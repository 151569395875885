import type { FC } from 'react';
import { useParams } from 'react-router-dom';
import InfoRequestTooltip from '../../../components/InfoRequestTooltip/InfoRequestTooltip.tsx';

import ReplacementCostGraph from '../ReplacementCostGraph/ReplacementCostGraph.tsx';
import { CHART_DATA_SET } from '../ReplacementCostGraph/ReplacementCostGraph.utils.ts';
import { HistoryDataStatuses } from '../../../types/history.type.ts';
import type { DefaultRouteParams } from '../../../types/route-params.type.ts';
import useUserQuery from '../../../api/user/use-user-query.ts';
import formatUtil from '../../../utils/format.util.ts';
import useReplacementCostHistoryQuery from '../../../api/history/use-replacement-cost-history-query copy.ts';
import { addressCSS, chartCSS, hintCSS, notificationCSS, titleCSS } from '../../../styles/chart.style.ts';
import { transformReplacementCostData } from '../../../utils/chart.util.ts';
import { SEGMENT } from '../../../constants/analytics.ts';
import type { ReplacementCostHistoryProps } from './ReplacementCostChart.type.ts';

const ReplacementCostHistory: FC<ReplacementCostHistoryProps> = ({ customCSS }) => {
  const { gid } = useParams() as DefaultRouteParams;
  const { data } = useReplacementCostHistoryQuery(gid);
  const { data: userData } = useUserQuery(gid);

  const address = userData?.user.address ? formatUtil.address(userData.user.address) : '';

  const getContent = () => {
    switch (data?.status) {
      case HistoryDataStatuses.Ready:
        return (
          <div css={chartCSS(false)}>
            <ReplacementCostGraph data={transformReplacementCostData(data?.history)} hidePreview={false} />
            <div css={hintCSS}>Hint: Hover over graph to see data per year.</div>
          </div>
        );
      case HistoryDataStatuses.Pending:
        return (
          <div css={chartCSS()}>
            <ReplacementCostGraph data={transformReplacementCostData(CHART_DATA_SET.history)} />
            <div css={notificationCSS}>
              <h3>Almost There!</h3>
              <p>We are still fetching quotes for your home. We will update you when the process is complete.</p>
            </div>
          </div>
        );
      case HistoryDataStatuses.Empty:
        return (
          <div css={chartCSS()}>
            <ReplacementCostGraph data={transformReplacementCostData(CHART_DATA_SET.history)} />
            <InfoRequestTooltip page={SEGMENT.pages.replacementCost} />
          </div>
        );
    }
  };

  return (
    <div css={customCSS}>
      <div css={titleCSS}>Suggested vs. current replacement cost</div>
      <p css={addressCSS}>🏠 {address}</p>
      {getContent()}
    </div>
  );
};

export default ReplacementCostHistory;
