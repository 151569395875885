import type { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../../shared/components/Button/Button.tsx';
import LockIcon from '../../../assets/svg/lock.svg?react';
import LockUnlockedIcon from '../../../assets/svg/lock-unlocked.svg?react';
import CheckboxMarkIcon from '../../../assets/svg/checkbox-mark.svg?react';
import Shield from '../../../assets/svg/checkbox-shield.svg?react';
import useDpNavigate from '../../../hooks/use-dp-navigate.ts';
import { DpCta } from '../../../types/digital-profile.type.ts';
import ROUTES from '../../../constants/routes.ts';
import type { DefaultRouteParams } from '../../../types/route-params.type.ts';
import useUserQuery from '../../../api/user/use-user-query.ts';
import useQuotesQuery from '../../../api/quotes/use-quotes-query.ts';
import { QuotingStatus } from '../../../types/quote.type.ts';
import useSegment from '../../../hooks/use-segment.ts';
import { SEGMENT } from '../../../constants/analytics.ts';
import {
  buttonCSS,
  checkListCSS,
  checkListItemCSS,
  checkListValueCSS,
  containerCSS,
  headerCSS,
  headingCSS,
  leftColCSS,
  rightColCSS,
} from './RateReviewInsightCard.style.ts';
import type { RateReviewInsightCardProps } from './RateReviewInsightCard.type.ts';
import { REVIEW_CHECKLIST_ITEMS } from './RateReviewInsightCard.util.ts';

const RateReviewInsightCard: FC<RateReviewInsightCardProps> = ({ customCSS }) => {
  const { gid } = useParams() as DefaultRouteParams;
  const { data: userData } = useUserQuery(gid);
  const profileCompleted = !!userData?.user.profile_completed_at;
  const { data: quotesData } = useQuotesQuery(gid, profileCompleted);

  const navigate = useNavigate();
  const navigateToDp = useDpNavigate();
  const { track } = useSegment();

  const noDataForQuotes = !profileCompleted || !quotesData?.quotes_request_status;

  const onActionClick = () => {
    if (profileCompleted) {
      track(SEGMENT.events.insightsNavigationClicked, {
        target_page: SEGMENT.pages.rateReview,
      });
      navigate(ROUTES.rateReview.replace(':gid', gid));
    }
    else {
      track(SEGMENT.events.unlockPortalDataRequested, {
        page: SEGMENT.pages.insights,
        location: SEGMENT.locations.rateReviewInsightCard,
      });
      navigateToDp(DpCta.Quotes);
    }
  };

  const getCheckListValue = () => {
    switch (true) {
      case noDataForQuotes:
        return (
          <>
            <span>Unknown</span>
            <LockIcon />
          </>
        );
      case quotesData?.quotes_request_status === QuotingStatus.Pending:
        return (
          <>
            <span>Loading...</span>
            <LockUnlockedIcon />
          </>
        );
      case quotesData?.quotes_request_status === QuotingStatus.Finished:
        return (
          <>
            <span>Done!</span>
            <CheckboxMarkIcon />
          </>
        );
    }
  };

  return (
    <div css={[containerCSS, customCSS]}>
      <div css={leftColCSS}>
        <h2 css={headerCSS}>
          <Shield /> <span>My Home Rate Review</span>
        </h2>
        <p>
          Matic has shopped your soon to be renewed policy and has found you a policy that might be a better fit.
        </p>
        <Button
          customCSS={buttonCSS}
          onClick={onActionClick}
        >
          {noDataForQuotes ? 'Unlock Your Rate Review' : 'View Your Quotes'}
        </Button>
      </div>
      <div css={rightColCSS}>
        <div css={headingCSS}>Review checklist</div>
        <div css={checkListCSS}>
          {REVIEW_CHECKLIST_ITEMS.map(item => (
            <div key={item} css={checkListItemCSS}>
              <div>{item}</div>
              <div css={checkListValueCSS}>
                {getCheckListValue()}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RateReviewInsightCard;
