import './Header.scss';

import { useParams } from 'react-router-dom';

import type { FC } from 'react';
import { PHONE_CONVERSATION_INITIATED } from '../../constants/analytics';
import { SVGUrls } from '../../constants/urls';
import useSettingsQuery from '../../api/settings/use-settings-query.ts';
import Header from '../../kit_deprecated/Header/Header.tsx';
import Icon from '../../kit_deprecated/Icon/Icon.tsx';
import analytics from '../../utils/analytics.util.ts';

function trackPhoneConversationInitiated(gid: string, page: string) {
  const props = {
    page,
    location: 'header',
  };

  analytics.trackV1(PHONE_CONVERSATION_INITIATED, gid, props);
}

interface Props {
  page: string;
}

const PageHeader: FC<Props> = ({ page }) => {
  const { data } = useSettingsQuery();
  const gid = (useParams as any)().gid;

  const onPhoneClick = () => trackPhoneConversationInitiated(gid, page);

  return (
    <Header>
      <a href={`tel:${data?.phone_number}`} className="c-page-header__call-agent-mobile" onClick={onPhoneClick}>
        <Icon iconName={SVGUrls.Phone} className="c-page-header__phone-icon" />
        Call Us
      </a>
      <div className="c-page-header__call-agent-desktop">
        <div className="c-page-header__agent-info">
          <div>
            Call a Matic agent at
            {' '}
            <a href={`tel:${data?.phone_number}`} className="o-link" onClick={onPhoneClick}>
              {data?.phone_number}
            </a>
          </div>
        </div>
        <div className="c-page-header__working-hours">
          {data?.working_hours?.map(wh => <div key={wh}>{wh}</div>)}
        </div>
      </div>
    </Header>
  );
};

export default PageHeader;
