export enum HistoryDataStatuses {
  Ready = 'ready',
  Pending = 'pending',
  Empty = 'empty',
};

export interface HistoryResponse<T = PremiumHistoryItem> {
  status: HistoryDataStatuses;
  history: HistoryData<T>;
}

export interface HistoryData<T = PremiumHistoryItem> {
  [key: string]: T;
}

export interface PremiumHistoryItem {
  policy_premium: string;
  quote_premium: string;
}
export interface ReplacementCostHistoryItem {
  replacement_cost: string;
  replacement_cost_min: string;
  replacement_cost_max: string;
}

export interface DataItem { year: string }

export interface TransformedPremiumDataItem {
  year: string;
  values: number[];
};

export type TransformedPremiumData = TransformedPremiumDataItem[];

export interface TransformedReplacementCostDataItem {
  year: string;
  values: [ { min: number; max: number }, number];
};

export type TransformedReplacementCostData = TransformedReplacementCostDataItem[];
