import { type SerializedStyles, css } from '@emotion/react';

import { mq } from '../../styles/media-queries.ts';

import { activeElementResetCSS } from '../../shared/shared.style.ts';
import THEME from '../../styles/theme.ts';

export const containerCSS = css`
  position: fixed;
  top: 0;
  width: 100%;
  height: ${THEME.size.headerHeight}px;
  display: flex;
  justify-content: space-between;
  padding: 10px 16px;
  box-sizing: border-box;
  border-bottom: 1px solid ${THEME.color.gray10};
  background-color: ${THEME.color.white};
  z-index: 99;
  ${mq[0]} {
    padding: 14px 40px;
  }
`;

export const leftColCSS = css`
  display: flex;
  align-items: center;
`;

export const menuButtonCSS = css`
  ${mq[0]} {
    display: none;
  }
`;

export const logoCSS = css`
  position: relative;
  top: 4px;
  height: 20px;
`;

export const pageLinksWrapperCSS = css`
  display: none;
  ${mq[0]} {
    display: flex;
    gap: 8px;
    align-items: center;
    margin-left: 16px;
  }
`;

export const pageLinkCSS = css`
  ${activeElementResetCSS};
`;

const pageLinkActive = css`
  color: ${THEME.color.azure50};
  background-color: ${THEME.color.azure95};
  font-weight: 700;
`;

export function pageLinkInnerCSS(isActive: boolean): SerializedStyles {
  return css`
  padding: 1px 4px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  border-radius: ${THEME.size.borderRadius}px;
  transition:
    color 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
  ${isActive && pageLinkActive};
  &:hover,
  &:active,
  &:focus {
    color: ${THEME.color.azure50};
  }
`;
}

export const rightColCSS = css`
  display: flex;
  align-items: center;
`;

export const accountButtonCSS = css`
  display: none;
  ${mq[0]} {
    ${activeElementResetCSS};
    display: block;
    margin-left: 16px;
  }
`;

export function accountButtonInnerCSS(isActive: boolean): SerializedStyles {
  return css`
  display: flex;
  align-items: center;
  padding: 1px 4px;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  transition:
    color 0.3s ease-in-out,
    background-color 0.3s ease-in-out;
  border-radius: ${THEME.size.borderRadius}px;
  &:hover,
  &:active,
  &:focus {
    color: ${THEME.color.azure50};
  }
  ${isActive
  && css`
    color: ${THEME.color.azure50};
    background-color: ${THEME.color.azure95};
  `}
`;
}

export const accountIconCSS = css`
  width: 16px;
  height: 16px;
  margin-right: 10px;
`;
