import type { FC } from 'react';
import PageContainer from '../../components/PageContainer/PageContainer.tsx';
import SectionHeading from '../../components/SectionHeading/SectionHeading.tsx';
import { pageHeadingCSS, pageSubheadingCSS } from '../../styles/layout.style.ts';
import { SEGMENT } from '../../constants/analytics.ts';
import useTrackPage from '../../hooks/use-track-page.ts';
import ScoreCards from './ScoreCards/ScoreCards.tsx';
import CoverageInsightCard from './CoverageInsightCard/CoverageInsightCard.tsx';
import HomeQuoteInsightCard from './HomeQuoteInsightCard/HomeQuoteInsightCard.tsx';
import RateReviewInsightCard from './RateReviewInsightCard/RateReviewInsightCard.tsx';
import AutoInsuranceInsightCard from './AutoInsuranceInsightCard/AutoInsuranceInsightCard.tsx';

const InsightsLayout: FC = () => {
  useTrackPage(SEGMENT.pages.insights);

  return (
    <PageContainer pageKey={SEGMENT.pages.insights}>
      <h1 css={pageHeadingCSS}>Protection Snapshot</h1>
      <p css={pageSubheadingCSS}>
        Your protection snapshot provides a view into how well your assets are protected and is a useful indicator of
        how much your insurance premiums will be.
      </p>

      <ScoreCards />

      <RateReviewInsightCard />

      <SectionHeading title="Is your home properly covered?" />
      <CoverageInsightCard />

      <SectionHeading title="Auto quote options" />
      <AutoInsuranceInsightCard />

      <SectionHeading title="Home quote options" />
      <HomeQuoteInsightCard />
    </PageContainer>
  );
};

export default InsightsLayout;
