import type { FC } from 'react';
import { Global } from '@emotion/react';
import { Outlet } from 'react-router-dom';
import { globalCSS } from '../../styles/global.style.ts';

const AuthRoot: FC = () => {
  return (
    <>
      <Global styles={globalCSS} />
      <Outlet />
    </>
  );
};

export default AuthRoot;
