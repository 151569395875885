import { useMutation, useQueryClient } from '@tanstack/react-query';

import API_PATH from '../../constants/api.ts';
import QUERY_CACHE_KEYS from '../../constants/query-cache-keys.ts';
import type { HTTPResponse } from '../../types/api.type.ts';
import type { UserResponse } from '../../types/user.type.ts';
import api from '../api.ts';

function useAuthenticate() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (data: { token: string; gid: string; marketing_engagement_gid?: string | null }) =>
      await api.post<HTTPResponse<UserResponse>>(API_PATH.createSession, { body: { data } }).then(async (res) => {
        await queryClient.refetchQueries({ queryKey: [QUERY_CACHE_KEYS.user, res.data.user.gid] });
        return res.data;
      }),
  });
}
export default useAuthenticate;
