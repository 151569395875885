import { type FC, useEffect, useRef, useState } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';

import useAuthenticate from '../../api/authentification/use-authenticate.ts';
import useUserQuery from '../../api/user/use-user-query.ts';
import ROUTES from '../../constants/routes.ts';
import FullPageLoader from '../../shared/components/FullPageLoader/FullPageLoader.tsx';
import type { User } from '../../types/user.type.ts';
import { getTargetPath } from '../../utils/routes.util.ts';

const Authenticate: FC = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const personGid = searchParams.get('gid');
  const marketingEngagementGid = searchParams.get('marketing_engagement_gid');
  const targetPage = searchParams.get('target_page');
  const authSend = useRef(false);
  const [userResponse, setUserResponse] = useState<User | null>(null);
  const [isAuthenticateError, setIsAuthenticateError] = useState(false);
  const { data: userData, isFetched: isUserFetched } = useUserQuery(personGid, false);
  const user = userData?.user || userResponse;

  const { mutateAsync: authenticate } = useAuthenticate();

  useEffect(() => {
    const call = async (tokenValue: string, personGidValue: string, marketingEngagementGidValue?: string | null) => {
      try {
        const response = await authenticate({
          token: tokenValue,
          gid: personGidValue,
          marketing_engagement_gid: marketingEngagementGidValue,
        });
        setUserResponse(response.user);
      }
      catch {
        setIsAuthenticateError(true);
      }
    };

    if (token && personGid && !authSend.current && isUserFetched && !userData?.user) {
      authSend.current = true;
      call(token, personGid, marketingEngagementGid);
    }
  }, [authenticate, isUserFetched, personGid, marketingEngagementGid, token, userData?.user]);

  if (!token || !personGid)
    return <Navigate to={ROUTES.problem} replace />;

  if (isAuthenticateError) {
    return <Navigate to={`${ROUTES.linkExpired}?gid=${personGid}&target_page=${targetPage}`} replace />;
  }

  if (!user)
    return <FullPageLoader />;

  return user?.verified
    ? (
        <Navigate to={getTargetPath(user.gid, targetPage)} replace />
      )
    : (
        <Navigate to={`${ROUTES.verify.replace(':gid', user.gid)}?target_page=${targetPage}`} replace />
      );
};

export default Authenticate;
