import type { FC } from 'react';

import { Navigate } from 'react-router-dom';
import PageInfoContainer from '../../components/PageInfoContainer/PageInfoContainer.tsx';
import ROUTES from '../../constants/routes.ts';
import useTrackPage from '../../hooks/use-track-page.ts';
import { SEGMENT } from '../../constants/analytics.ts';
import SignInForm from './SignInForm/SignInForm.tsx';

import { signInFormCSS } from './index.style.ts';

const SignInLayout: FC = () => {
  useTrackPage(SEGMENT.pages.signIn);

  // TODO: Remove before v2 release
  if (window.location.host.includes('my.matic.com')) {
    return <Navigate to={ROUTES.problem} />;
  }

  return (
    <PageInfoContainer
      heading="Sign into Matic"
      subHeading="To login to Matic please enter your email below to get your access link."
    >
      <SignInForm customCSS={signInFormCSS} />
    </PageInfoContainer>
  );
};

export default SignInLayout;
