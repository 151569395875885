import type { SerializedStyles } from '@emotion/react';

import { type QuestionProps, QuestionType } from '../../../components/Question/Question.type.ts';
import { PERSON_AGE_VALIDATION, REQUIRED_VALIDATION } from '../../../utils/question-validations.util.ts';

export interface UserFormProps {
  customCSS?: SerializedStyles;
}

export interface UserFormState {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  date_of_birth: string;
}

export const USER_FORM_SCHEMA: QuestionProps[] = [
  {
    type: QuestionType.Text,
    questionKey: 'first_name',
    label: 'First Name',
    validations: [REQUIRED_VALIDATION],
    isDisabled: false,
    gridWidth: 6,
    dense: true,
    trackingForbidden: true,
  },
  {
    type: QuestionType.Text,
    questionKey: 'last_name',
    label: 'Last Name',
    validations: [REQUIRED_VALIDATION],
    isDisabled: true,
    gridWidth: 6,
    dense: true,
    trackingForbidden: true,
  },
  {
    type: QuestionType.Email,
    questionKey: 'email',
    label: 'Email',
    placeholder: 'xxxxxx@xxxxx.com',
    validations: [REQUIRED_VALIDATION],
    isDisabled: true,
    gridWidth: 6,
    dense: true,
    trackingForbidden: true,
  },
  {
    type: QuestionType.Phone,
    questionKey: 'phone',
    label: 'Phone Number',
    validations: [REQUIRED_VALIDATION],
    isDisabled: false,
    gridWidth: 6,
    dense: true,
    trackingForbidden: true,
  },
  {
    type: QuestionType.Date,
    questionKey: 'date_of_birth',
    label: 'Birthday',
    validations: [REQUIRED_VALIDATION, ...PERSON_AGE_VALIDATION],
    isDisabled: false,
    gridWidth: 6,
    dense: true,
    trackingForbidden: true,
  },
];
