import { css } from '@emotion/react';
import THEME from '../../styles/theme.ts';

export const factorsContainerCSS = css`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: space-between;
  margin-bottom: 32px;
`;

export const factorCSS = css`
  padding-bottom: 12px;
  border-bottom: 1px solid ${THEME.color.gray30}; //TODO fix colors after design review by Oles
`;
