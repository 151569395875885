import { css } from '@emotion/react';

export const containerCSS = css`
  margin-bottom: 32px;
`;

export const imageCSS = css`
  max-width: 100%;
  max-height: 172px;
`;
