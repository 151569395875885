import { differenceInDays, differenceInYears, isValid, parseISO, startOfDay } from 'date-fns';
import type { Validate, ValidationValue } from 'react-hook-form';

import { toCamelCase } from '../shared/utils/to-camel-case.util.ts';
import type { AddressAnswer, AnswerValue } from '../types/answer.type.ts';
import {
  type CustomValidations,
  type HardcodedValidations,
  type ValidateFn,
  type Validation,
  type ValidationRules,
  ValidationType,
} from '../types/validation.type.ts';

export const REQUIRED_VALIDATION = {
  key: ValidationType.Required,
  value: true,
  message: 'This field is required',
};

export const PERSON_AGE_VALIDATION = [
  {
    key: ValidationType.MaxDaysFromNow,
    value: 0,
    message: 'Please check your date of birth',
  },
  {
    key: ValidationType.MaxYears,
    value: 150,
    message: 'Please check your date of birth',
  },
];

export const ZIP_CODE_VALIDATION = {
  key: ValidationType.Pattern,
  value: '^[0-9-]+$',
  message: 'ZIP code can contain only digits and a hyphen',
};

const DEFAULT_VALIDATION_MSG = 'Validation failed';

const STATIC_RULES: ValidationType[] = [
  ValidationType.Required,
  ValidationType.Min,
  ValidationType.Max,
  ValidationType.MinLength,
  ValidationType.MaxLength,
  ValidationType.Pattern,
];

interface ValidationDates {
  now: number;
  valueToCompare: number;
  date: Date;
}

const isValidDate: ValidateFn = value =>
  !value || typeof value !== 'string' || isValid(parseISO(value || '')) || 'Please enter a valid date';

const isValidAddress: ValidateFn = (value) => {
  if (value && !!(value as AddressAnswer).line1) {
    return true;
  }
  return 'Please search and select the address from the list.';
};

const isNotEmptyString: ValidateFn = value =>
  typeof value === 'string' ? (value ? !!value.trim() || 'This value cannot consist of empty spaces' : true) : true;

function getValidationDates(answerValue: AnswerValue, validationValue?: ValidationValue): ValidationDates {
  return {
    now: startOfDay(Date.now()).getTime(),
    valueToCompare: typeof validationValue === 'number' ? validationValue : Number.parseInt(validationValue as string),
    date: parseISO((answerValue as string) || ''),
  };
}

const minDaysFromNow: ValidateFn = (answerValue, validationValue, message = DEFAULT_VALIDATION_MSG) => {
  const { now, valueToCompare: minDays, date } = getValidationDates(answerValue, validationValue);

  return (isValid(date) && differenceInDays(date, now) >= minDays) || message;
};

const maxDaysFromNow: ValidateFn = (answerValue, validationValue, message = DEFAULT_VALIDATION_MSG) => {
  const { now, valueToCompare: maxDays, date } = getValidationDates(answerValue, validationValue);

  return (isValid(date) && differenceInDays(date, now) <= maxDays) || message;
};

const minDaysBeforeNow: ValidateFn = (answerValue, validationValue, message = DEFAULT_VALIDATION_MSG) => {
  const { now, valueToCompare: minDays, date } = getValidationDates(answerValue, validationValue);

  return (isValid(date) && differenceInDays(now, date) >= minDays) || message;
};

const maxDaysBeforeNow: ValidateFn = (answerValue, validationValue, message = DEFAULT_VALIDATION_MSG) => {
  const { now, valueToCompare: maxDays, date } = getValidationDates(answerValue, validationValue);

  return (isValid(date) && differenceInDays(now, date) <= maxDays) || message;
};

const minYears: ValidateFn = (answerValue, validationValue, message = DEFAULT_VALIDATION_MSG) => {
  const { now, valueToCompare: minYr, date } = getValidationDates(answerValue, validationValue);

  return (isValid(date) && differenceInYears(now, date) >= minYr) || message;
};

const maxYears: ValidateFn = (answerValue, validationValue, message = DEFAULT_VALIDATION_MSG) => {
  const { now, valueToCompare: maxYr, date } = getValidationDates(answerValue, validationValue);

  return (isValid(date) && differenceInYears(now, date) <= maxYr) || message;
};

export const hardcodedValidations: Record<HardcodedValidations, ValidateFn> = {
  isValidDate,
  isValidAddress,
  isNotEmptyString,
};

export const customValidations: Record<CustomValidations, ValidateFn> = {
  minDaysFromNow,
  maxDaysFromNow,
  minDaysBeforeNow,
  maxDaysBeforeNow,
  minYears,
  maxYears,
};

export function getValidationRules(
  validations?: Validation[],
  existingValidateFns: Record<string, Validate<AnswerValue, any>> = {},
): ValidationRules {
  if (!validations?.length) {
    return { validate: { ...existingValidateFns } };
  }
  return validations.reduce(
    (acc, { key, value, message }) => {
      switch (true) {
        case key === ValidationType.Required:
          return {
            ...acc,
            [key]: message,
          };
        case key === ValidationType.Pattern:
          return {
            ...acc,
            [key]: {
              value: new RegExp(value as string),
              message,
            },
          };
        case STATIC_RULES.includes(key):
          return {
            ...acc,
            [toCamelCase(key)]: { value, message },
          };
        default: {
          const validationFnKey = toCamelCase(key);
          const validationFn = customValidations[validationFnKey as CustomValidations];
          if (!validationFn) {
            return acc;
          }
          return {
            ...acc,
            validate: {
              ...acc.validate,
              [validationFnKey]: (answerValue: AnswerValue) => validationFn(answerValue, value, message),
            },
          };
        }
      }
    },
    {
      validate: { ...existingValidateFns },
    },
  );
}

/**
 * Clear all validations except required to allow "*" in value
 */
export function getHiddenQuestionValidationRules(validations?: Validation[]): ValidationRules {
  const requiredRule = validations?.find(rule => rule.key === ValidationType.Required);
  return requiredRule ? { [requiredRule.key]: requiredRule.message } : {};
}
