import { type FC, useId } from 'react';

import Tooltip from '@mui/material/Tooltip';

import InfoButtonIcon from '../../../assets/svg/info-button.svg?react';
import { TooltipTheme, muiTooltipCSS, tooltipArrowCSS, tooltipContentCSS } from '../../../styles/common.style.ts';
import type { TooltipQuestionButtonProps } from './TooltipQuestionButton.type.ts';

import { infoMarkCSS, tooltipButtonCSS } from './TooltipQuestionButton.style.ts';

const TooltipQuestionButton: FC<TooltipQuestionButtonProps> = ({
  customCSS,
  id,
  content,
  size = 18,
  theme = TooltipTheme.Dark,
}) => {
  const generatedId = useId();
  const key = id ?? generatedId;

  return (
    <Tooltip
      id={`tooltip-${key}`}
      arrow
      placement="top"
      componentsProps={{
        tooltip: { sx: { ...muiTooltipCSS(theme) } },
        arrow: { sx: { ...tooltipArrowCSS(theme) } },
      }}
      // eslint-disable-next-line react-dom/no-dangerously-set-innerhtml
      title={<div css={tooltipContentCSS(theme)} dangerouslySetInnerHTML={{ __html: content }} />}
      enterTouchDelay={0}
      leaveTouchDelay={100000}
    >
      <button
        data-testid="tooltip"
        type="button"
        css={[tooltipButtonCSS(size), customCSS]}
        data-tip="tooltip"
        aria-label={content}
        data-for={`tooltip-${key}`}
      >
        <InfoButtonIcon css={infoMarkCSS(size)} />
      </button>
    </Tooltip>
  );
};

export default TooltipQuestionButton;
