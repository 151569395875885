import { useCallback } from 'react';

import { useParams } from 'react-router-dom';

import analytics from '../utils/analytics.util.ts';

function useSegment() {
  const { gid } = useParams<{ gid: string }>();

  const track = useCallback(
    (eventName: string, config: Record<string, unknown> = {}): void => {
      analytics.track(eventName, {
        account_gid: gid || null,
        session_gid: null, // TODO: Get sessionGid
        ...config,
      });
    },
    [gid],
  );

  const page = useCallback(
    (pageName: string, config: Record<string, unknown> = {}): void =>
      analytics.page(pageName, {
        account_gid: gid || null, // TODO: Add account_gid to Segment schema
        session_gid: null, // TODO: Get sessionGid, TODO: Add account_gid to Segment schema
        ...config,
      }),
    [gid],
  );

  return {
    track,
    page,
  };
}

export default useSegment;
