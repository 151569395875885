import { type FC, useState } from 'react';

import ProposalCard from '../../../components/ProposalCard/ProposalCard';
import SIRV_IMAGES from '../../../constants/sirv-images';
import Button from '../../../shared/components/Button/Button';
import THEME from '../../../styles/theme';
import AgentInfoModal from '../AgenContactInfoModal/AgentContactInfoModal';
import { bodyCSS, buttonCSS, containerCSS, headerCSS, textBodyCSS } from './AutoQuoteAd.style';
import type { AutoQuoteAdProps } from './AutoQuoteAd.type';

const AutoQuoteAd: FC<AutoQuoteAdProps> = ({ agentContactInfo, isProposalExpired }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  return (
    <ProposalCard backgroundColor={THEME.color.positiveGreen94}>
      <div css={containerCSS}>
        <div css={bodyCSS}>
          <div css={textBodyCSS}>
            <p css={headerCSS}>Want to save even more?</p>
            <p>
              Did you know you could save up to 20% when Matic shops your auto insurance, too? We work hard to find
              discounts – even checking to see if using two carriers gets better rates than bundling. Chat with a
              licensed advisor to get a free auto quote.
            </p>
          </div>
          <img src={SIRV_IMAGES.car} width="265" height="135" alt="Blue car" />
        </div>
        <Button customCSS={buttonCSS} onClick={() => setModalOpen(true)}>
          Contact Your Advisor
        </Button>
      </div>
      {isModalOpen && (
        <AgentInfoModal
          agentContactInfo={agentContactInfo}
          setModalOpen={setModalOpen}
          isProposalExpired={isProposalExpired}
        />
      )}
    </ProposalCard>
  );
};

export default AutoQuoteAd;
