import type { FC } from 'react';

import Button from '../../../shared/components/Button/Button.tsx';
import { Card } from '../../../shared/components/Card/Card.tsx';
import SkeletonLoader from '../../../shared/components/SkeletonLoader/SkeletonLoader.tsx';
import ScoreCardContent from '../ScoreCardContent/ScoreCardContent.tsx';
import ScoreFactors from '../ScoreFactors/ScoreFactors.tsx';
import {
  buttonCSS,
  cardInnerCSS,
  containerCSS,
  factorsCSS,
  iconWrapperCSS,
  titleCSS,
} from './ScoreCard.style';
import type { ScoreCardProps } from './ScoreCard.type';
import { ScoreGaugeType } from './ScoreCard.type';

const ScoreCard: FC<ScoreCardProps> = ({
  customCSS,
  title,
  description,
  icon,
  scoreGaugeType = ScoreGaugeType.Positive,
  score,
  scoreMax,
  factors,
  tooltip = '',
  scoreButtonLabel = 'View all factors',
  isLoading,
  onClick,
  onViewAllFactorsClick,
}) => {
  if (isLoading) {
    return (
      <Card customCSS={[containerCSS, customCSS]}>
        <SkeletonLoader width={50} absoluteHeight={28} marginBottom={20} centered />
        <SkeletonLoader width={70} absoluteHeight={146} marginBottom={20} centered />
        <SkeletonLoader width={35} absoluteHeight={20} marginBottom={12} />

        <SkeletonLoader width={100} absoluteHeight={40} marginBottom={12} />
        <SkeletonLoader width={100} absoluteHeight={40} marginBottom={12} />
        <SkeletonLoader width={100} absoluteHeight={40} marginBottom={20} />

        <SkeletonLoader width={55} absoluteHeight={20} />
      </Card>
    );
  }

  return (
    <Card customCSS={[containerCSS, customCSS]}>
      <div css={titleCSS}>{title}</div>
      {typeof score === 'number' && typeof scoreMax === 'number'
        ? (
            <ScoreCardContent
              score={score}
              scoreMax={scoreMax}
              scoreGaugeType={scoreGaugeType}
              tooltip={tooltip}
            />
          )
        : (
            <>
              <div css={cardInnerCSS}>
                <div css={iconWrapperCSS}>{icon}</div>
                <div>{description}</div>
              </div>
              <Button css={buttonCSS} onClick={onClick}>
                Get your score
              </Button>
            </>
          )}

      <ScoreFactors
        customCSS={factorsCSS}
        factors={factors || []}
        scoreButtonLabel={scoreButtonLabel}
        onActionClick={onViewAllFactorsClick}
      />
    </Card>
  );
};

export default ScoreCard;
