import { useMutation } from '@tanstack/react-query';

import API_PATH from '../../constants/api.ts';
import type { HTTPResponse } from '../../types/api.type.ts';
import type { TargetPage } from '../../types/target-page.ts';
import api from '../api.ts';

function useSendMagicLink(throwOnError = true) {
  return useMutation({
    throwOnError,
    mutationFn: async (data: { gid?: string; email?: string; target_page?: TargetPage }) =>
      await api
        .post<HTTPResponse<{ user: { email: string } }>>(API_PATH.magicLink, { body: { data } })
        .then(async res => res.data),
  });
}
export default useSendMagicLink;
