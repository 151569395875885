import { type SerializedStyles, css } from '@emotion/react';

import { mq } from '../../../styles/media-queries';
import THEME from '../../../styles/theme.ts';
import type { ButtonSize, ButtonVariant } from './Button.type.ts';

function buttonColors(bgc: string, bc: string, c: string): SerializedStyles {
  return css`
  background-color: ${bgc};
  border-color: ${bc};
  color: ${c};
`;
}

const smallButtonStyles = css`
  ${mq[0]} {
    font-size: 14px;
    line-height: 20px;
    padding: 4px 12px;
    border-width: 2px;
  }
`;

const largeButtonStyles = css`
  ${mq[0]} {
    font-size: 20px;
    line-height: 1.4;
    padding: 12px 24px;
    border-width: 4px;
  }
`;

const textButtonStyles = css`
  padding: 0;
  border: none;
  border-radius: 0;
  font-size: inherit;
  line-height: inherit;
  font-weight: inherit;
`;

function getButtonColors(variant: ButtonVariant): SerializedStyles {
  switch (variant) {
    case 'text':
    case 'text_inverted':
      return buttonColors('transparent', 'transparent', THEME.color.azure50);
    case 'secondary':
      return buttonColors(THEME.color.white, THEME.color.azure50, THEME.color.azure50);
    case 'inverted':
      return buttonColors(THEME.color.white, THEME.color.white, THEME.color.azure50);
    default:
      return buttonColors(THEME.color.azure50, THEME.color.azure50, THEME.color.white);
  }
}

function getDisabledColors(variant: ButtonVariant): SerializedStyles {
  switch (variant) {
    case 'text':
    case 'text_inverted':
      return buttonColors('transparent', 'transparent', THEME.color.gray60);
    case 'secondary':
      return buttonColors(THEME.color.white, THEME.color.gray30, THEME.color.gray60);
    default:
      return buttonColors(THEME.color.gray10, THEME.color.gray10, THEME.color.gray60);
  }
}

function getActiveFocusColors(variant: ButtonVariant): SerializedStyles {
  switch (variant) {
    case 'text':
      return buttonColors('transparent', 'transparent', THEME.color.azure50);
    case 'text_inverted':
      return buttonColors('transparent', 'transparent', THEME.color.azure68);
    default:
      return buttonColors(THEME.color.azure50, THEME.color.azure50, THEME.color.white);
  }
}

function getHoverColors(variant: ButtonVariant): SerializedStyles {
  switch (variant) {
    case 'text':
      return buttonColors('transparent', 'transparent', THEME.color.azure68);
    case 'text_inverted':
      return buttonColors('transparent', 'transparent', THEME.color.azure95);
    default:
      return buttonColors(THEME.color.azure68, THEME.color.azure68, THEME.color.white);
  }
}

function getButtonVariant(variant: ButtonVariant): SerializedStyles {
  switch (variant) {
    case 'text':
    case 'text_inverted':
      return textButtonStyles;
    default:
      return css``;
  }
}

export function buttonCSS(
  size: ButtonSize,
  variant: ButtonVariant = 'default',
  fullWidth = false,
  hasIcon = false,
): SerializedStyles {
  return css`
    ${getButtonColors(variant)};
    font-family: ${THEME.typography.fontFamily};
    font-size: ${THEME.typography.fontSize}px;
    line-height: ${THEME.typography.lineHeight};
    font-weight: 700;
    border-radius: ${THEME.size.borderRadius}px;
    border-style: solid;
    border-width: 2px;
    box-sizing: border-box;
    cursor: pointer;
    -webkit-font-smoothing: antialiased;
    max-width: 100%;
    transition:
      color 0.3s,
      background-color 0.3s,
      border-color 0.3s;
    padding: 10px 12px;
    position: relative;
    ${hasIcon
    && css`
      display: inline-flex;
      align-items: center;
    `}
    ${fullWidth
    && css`
      width: 100%;
    `}
    ${getButtonVariant(variant)};

    &:hover {
      ${getHoverColors(variant)}
    }

    &:active,
    &:focus {
      ${getActiveFocusColors(variant)}
    }

    &:disabled,
    &:disabled:hover {
      cursor: not-allowed;
      ${getDisabledColors(variant)}
    }
    ${size === 'large' && largeButtonStyles};
    ${size === 'small' && smallButtonStyles};
  `;
}

export const loaderWrapperCSS = css`
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`;

export function iconCSS(isLarge = false): SerializedStyles {
  return css`
  display: flex;
  margin-right: 12px;

  svg {
    width: 16px;
    height: 16px;
  }

  ${mq[0]} {
    svg {
      width: ${isLarge ? 24 : 16}px;
      height: ${isLarge ? 24 : 16}px;
    }
  }
`;
}
