import { css } from '@emotion/react';
import THEME from '../../styles/theme.ts';
import { mq } from '../../styles/media-queries.ts';

export const tabsCSS = css`
  display: flex;
  align-items: baseline;
  gap: 32px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  color: ${THEME.color.gray60};
`;

export const tabWithTagCSS = css`
  display: flex;
  gap: 0 8px;
  flex-wrap: wrap;
  align-items: center;
`;

export const activeTabCSS = css`
  color: ${THEME.color.azure50};
  flex-shrink: 0;
  border-bottom: 2px solid ${THEME.color.azure50};
`;

export const tagCSS = css`
  padding: 4px 12px;
  background-color: ${THEME.color.gray10};
  border-radius: 40px;
  display: inline-flex;
`;

export const factorsContainerCSS = css`
  display: flex;
  justify-content: space-between;
  gap: 16px 32px;
  flex-wrap: wrap;
  ${mq[0]} {
    gap: 16px 48px;
  }
`;

export const factorCSS = css`
  width: 100%;
  ${mq[0]} {
    width: calc(50% - 24px);
  }
  ${mq[1]} {
    flex-basis: 360px;
  }
`;
