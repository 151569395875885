import type { FC } from 'react';

import { containerCSS, subtitleCSS, titleCSS, titleIconCSS } from './SectionHeading.style';
import type { SectionHeadingProps } from './SectionHeading.type';

const SectionHeading: FC<SectionHeadingProps> = ({ customCSS, title, subtitle, icon, size = 'regular' }) => {
  return (
    <div css={[containerCSS, customCSS]}>
      <h2 css={titleCSS(size, !!icon)}>
        <div css={titleIconCSS}>{icon}</div>
        {title}
      </h2>
      {subtitle && <p css={subtitleCSS(size)}>{subtitle}</p>}
    </div>
  );
};

export default SectionHeading;
